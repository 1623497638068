import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import flow from './modules/flow';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		account,
		flow
	}
});
