<template>
	<div class="progress">
		<span class="progress-bar-text">{{ percent }}%</span>
		<div class="progress-bar" :style="{ width: percent + '%' }"></div>
	</div>
</template>

<script>
export default {
	name: 'Progress',
	props: {
		percent: {
			type: Number || String,
			required: true,
			validator: function(value) {
				return value >= 0 && value <= 100;
			}
		}
	}
};
</script>

<style scoped>
.progress {
	position: relative;
	height: 13px;
	background-color: #f0f0f0;
	border-radius: 3px;
	overflow: hidden;
}

.progress-bar {
	height: 100%;
	background-color: #1890ff;
	transition: width 0.6s ease;
}

.progress-bar-text {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	line-height: 13px;
	font-size: 12px;
	font-weight: bold;
}
</style>
