import db from "@/common/localstorage";

export default (Vue)=>{

    /** 单个权限 **/
    Vue.directive('has', {
        inserted: function(el, binding,c) {
            if (!Vue.prototype.$_has(binding.value)) {
                el.parentNode.removeChild(el)
            }
        }
    })

    /** 必须同时满足多个权限 **/
    Vue.directive('has-and', {
        inserted: function(el, binding,c) {
            let sa = binding.value.split('|');
            let res = true;
            sa.forEach(x=>{
                if (!Vue.prototype.$_has(x)) {
                    res = false;
                }
            })
            if(!res){
                el.parentNode.removeChild(el)
            }
        }
    })
    
    /** 只要满足其中一个权限 **/
    Vue.directive('has-or', {
        inserted: function(el, binding,c) {
            let sa = binding.value.split('|');
            let res = false;
            sa.forEach(x=>{
                if (Vue.prototype.$_has(x)) {
                    console.log(x)
                    res = true;
                }
            })
            if(!res){
                el.parentNode.removeChild(el)
            }
        }
    })


    // 权限检查方法（且把该方法添加到vue原型中）
    Vue.prototype.$_has = function(value) {
        let isExist = false
        // 从浏览器缓存中获取权限数组（该数组在登入成功后拉取用户的权限信息时保存在浏览器的缓存中）
        let permissions = db.get('PERMISSIONS');
        if (permissions === undefined || permissions == null) {
            return false
        }
        if (permissions.indexOf(value) >= 0) {
            // 若在按钮中定义的权限字段能在后端返回的权限数组中能找到，则该按钮可显示
            isExist = true
        }
        return isExist
    }
}
