import calc from './calc.vue';
export default {
	type: 'calc', // 表单类型
	label: '计算控件', // 标题文字
	icon: 'icon-gallery',
	component: calc,
	options: {
		defaultValue: '',
		width: '100%',
		readonly: true,
		disabled: false,
		showLabel: false,
		placeholder: '计算控件',
		formula: '' //计算公式
	},
	model: '',
	key: ''
};
