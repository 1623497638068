<template>
    <a-modal v-model="visible" :title="false" width="50%" :footer="null" @cancel="_close" centered destroyOnClose>
        <a-page-header bordered :ghost="false" :title="data.title">
            <a-descriptions size="small" :column="3">
                <a-descriptions-item label="发布人">
                    {{ data.staffName }}
                </a-descriptions-item>
                <a-descriptions-item label="发布时间">
                    {{ data.createTime }}
                </a-descriptions-item>
            </a-descriptions>
            <template slot="footer">
                <div>
                    <div style="padding: 10px 0;border-top: 1px solid #f7f7f7;" v-html="data.content"></div>
                </div>
                <div>
                    <attach-view :attachment="data.attachment"></attach-view>
                </div>

            </template>

        </a-page-header>
    </a-modal>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
import attachView from '@/components/common/attach-view.vue';
export default {
    components: { attachView },
    data() {
        return {
            visible: false,
            loading: false,
            data: {
                title: '',
                content: '',
                createTime: ''
            }
        }
    },
    methods: {
        ...apiUtil,
        show(newsId) {
            utils.showSpin()
            request.get("/platform/notice/detail/" + newsId, {}).then(ret => {
                // console.log(ret)
                this.data = ret.data;
                this.visible = true;
            })
        },
        _close() {
            this.visible = false;
        }
    }
}
</script>
