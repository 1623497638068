<template>
	<div>
		<a-table :scroll="{ x: '100%' }" :style="{ background: '#FFF' }" size="small" :columns="columns" ref="list"
						 :data-source="data.records" :loading="loading" :bordered="false" :pagination="false" row-key="id">
			<!-- 内容 -->
			<template slot-scope="row" slot="content">
				<div v-html="row.content" @click="detailCon(row.content)"></div>
			</template>
			<!-- 进度 -->
			<template slot-scope="row" slot="process">
				<Progress :percent="row.process"></Progress>
			</template>
			<!-- 附件 -->
			<template slot-scope="row" slot="attachment">
				<attachView style="margin-top:-10px" :istitle="false" :attachment="row.attachment"></attachView>
			</template>
			<!-- 操作 -->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip v-if="row.is" title="修改"><a-button type="link" @click="$refs.logs._show(row)"
											icon="edit"></a-button></a-tooltip>
					<a-tooltip v-if="row.is" title="删除"><a-button @click="del(row.id)" type="link"
											icon="delete"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-row>
			<a-col offset="2" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="total" @change="getNext" :pageSizeOptions="pageSizeOptions"
											@showSizeChange="setLimit" :current="param.page" />
			</a-col>

		</a-row>
		<a-modal :visible="visible" @ok="visible = false" @cancel="visible = false">
			<div v-html="ModalText"></div>
		</a-modal>
		<logsList @success="success" ref="logs"></logsList>
	</div>
</template>
<script>
import attachView from '@/components/common/attach-view.vue';
import Progress from './progress.vue';

import utils from '@/common/utils';
import apiUtils from '@/common/apiUtil';
import logsList from './logsList.vue';
export default {
	components: { attachView, Progress, logsList },
	data() {
		return {
			loading: false,
			data: {},
			ModalText: '',
			visible: false,
			total: 0,
			pageSizeOptions: ['1', '2', '5', '10', '15'],
			staffId: apiUtils.getUser().staffId,
			isdel: false,
			param: {
				page: 1,
				limit: 10,
				keyword: ''
			},
			columns: [
				{ title: '姓名', dataIndex: 'staffName', width: 100, align: 'center' },
				{ title: '日志内容', scopedSlots: { customRender: 'content' }, width: 180, ellipsis: true, align: 'center' },
				{ title: '进度', scopedSlots: { customRender: 'process' }, width: 120 },
				{ title: '附件', scopedSlots: { customRender: 'attachment' }, align: 'left' },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'left', width: 80 }
			]
		};
	},
	mounted() {
		this.getList();
	},
	props: ['planId'],
	methods: {
		detailCon(content) {
			this.ModalText = content;
			this.visible = true;
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get(`/platform/work-plan/logs/${this.planId}`, this.param)
				.then(ret => {
					ret.data.records.forEach(item => {
						if (item.staffId == this.staffId) {
							item.is = true;
						} else {
							item.is = false;
						}
					});
					this.data = ret.data;
					this.total = ret.data.total;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		success() {
			this.getList();
		},
		// 删除
		del(id) {
			utils.confirm('您确认要删除此分类吗?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/work-plan/logs/del/' + id).then(ret => {
					this.getList();
				});
			});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style scoped>
.content {
	box-sizing: border-box;
	position: relative;
	padding: 0 24px;
}

.container {
	font-size: 20px;
}
</style>
