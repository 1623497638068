<template>
	<a-tree-select v-model="roleIds" :treeData="treeData" :treeDefaultExpandedKeys="getExpandedKeys" style="width: 100%;"
		placeholder="请选择角色" :multiple="multiple"
		:replaceFields="{ title: 'name', value: 'roleId', children: 'children', key: 'roleId' }" :tree-checkable="multiple"
		@change="changeData" showCheckedStrategy="SHOW_ALL" :disabled="disabled" allowClear></a-tree-select>
</template>

<script>
/**
 * 部门选择组件
 * @example <select-role v-model="formData.scopeRoleIds" :organId="formData.organId" :multiple="true"></select-role>
 * @example <select-role v-model="formData.roleId" :organId="formData.organId" :multiple="false"></select-role>
 */
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtils from '@/common/apiUtil';
export default {
	data() {
		return {
			isLoading: false,
			roleIds: [],
			treeData: []
		};
	},
	mounted() {
		this.getTreeData();
	},
	methods: {
		changeData(value, label, extra) {
			let result = this.roleIds ? this.roleIds : '';
			this.$emit('input', result);
			setTimeout(() => {
				this.$emit('change', result);
			}, 300);
		},
		// 获取数据
		getTreeData() {
			if (this.isLoading || this.organId == '') {
				return;
			}
			this.isLoading = true;
			request
				.get('/platform/role/scope-list', { organId: this.organId, sys: this.system ? 1 : 0, isAll: this.isAll ? 1 : 0 })
				.then(ret => {
					this.treeData = ret.data;
				})
				.finally(() => {
					this.isLoading = false;
				});
		}
	},
	computed: {
		getExpandedKeys() {
			if (typeof this.roleIds != 'object') {
				const id = parseInt(this.roleIds);
				return [id];
			} else {
				return this.roleIds;
			}
		}
	},
	props: {
		value: {
			type: Object | Array | String | Number,
			default: () => {
				return [];
			}
		},
		// 是否可多选
		multiple: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
		disabled: {
			type: Boolean,
			default: () => false
		},
		//是否获取系统角色
		system: {
			type: Boolean,
			default: () => false
		},
		organId: {
			type: String | Number,
			default: () => {
				return apiUtils.getDefaultOrganId();
			}
		},
		//获取所有单位的数据
		isAll: {
			type: Boolean,
			default: () => false
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	watch: {
		value: {
			handler(v) {
				setTimeout(() => {
					if (utils.isEmpty(v)) {
						this.roleIds = [];
					} else {
						this.roleIds = this.multiple ? v : [v];
					}
				}, 300);
			},
			deep: true,
			immediate: true
		},
		organId(v) {
			if (utils.isEmpty(v)) {
				return;
			}
			// this.roleIds = [];
			this.getTreeData();
		}
	}
};
</script>

<style></style>
