import { socketUrl, websocket_status } from '@/config/request';
import { notification } from 'ant-design-vue';
import db from '@/common/localstorage';
notification.config({
	top: 90
});

//全局websocket对象
export let websocket;
//心跳
let heartbeat = null;
//是否需要重连
let isResetConcat = true;
//重连对象
let RConcat = null;

/**
 * 初始化连接
 */
export function init() {
	let userToken = db.get('USER_TOKEN');
	if (websocket && websocket.readyState == 1) {
		return;
	}
	if (userToken && typeof userToken == 'string') {
		let url = socketUrl + '?token=' + userToken;
		websocket = new WebSocket(url);
		websocket.onopen = openEvent;
		websocket.onclose = closeEvent;
		websocket.onerror = errorEvent;
		websocket.onmessage = messageEvent;
	}
}

/**
 * 手动关闭链接
 * @param status
 */
export function close(status) {
	isResetConcat = status ? status : true;
	if (websocket && websocket.readyState == 1) {
		websocket.close();
	}
}

/**
 * 发送消息
 * @param type
 * @param data
 */
function sendMsg(type, data) {
	if (websocket == null || websocket.readyState == false) {
		console.log('服务器未连接！');
		return false;
	}
	let jsonData = {
		cmd: type,
		data: JSON.stringify(data)
	};
	websocket.send(JSON.stringify(jsonData));
}

/**
 * 接收消息事件
 * @param res
 */
function messageEvent(res) {
	// console.log('-----------------收到服务器消息-------------------');
	let ret = JSON.parse(res.data);
	if (ret.cmd == 'NOTICE') {
		app.$emit('notice-message', ret.data);
	} else {
		console.log('未知消息类型');
	}
}

/**
 * 连接成功事件
 * @param res
 */
function openEvent(res) {
	console.log('----------连接成功-----------');
	start();
	//关闭重连对象
	if (RConcat) {
		clearInterval(RConcat);
		RConcat = null;
	}
}

/**
 * 心跳
 */
function start() {
	heartbeat = setInterval(() => {
		if (websocket.readyState) {
			sendMsg('ping', { time: new Date() });
		} else {
			clearInterval(heartbeat);
		}
	}, 50000);
}

/**
 * 断线重连
 */
function resetConcat() {
	//重连
	if (isResetConcat == false || RConcat) {
		return;
	}
	//开始重连
	let i = 1;
	RConcat = setInterval(() => {
		console.log('--------尝试第' + i + '次连接--------');
		init();
		i++;
	}, 5000);
}

/**
 * 连接断开
 * @param res
 */
function closeEvent(res) {
	// console.log('--------连接已断开--------')
	clearInterval(heartbeat);
	resetConcat();
}

/**
 * 连接错误
 * @param error
 */
function errorEvent(error) {
	// console.log('--------连接错误--------')
	// console.log(error)
	clearInterval(heartbeat);
	resetConcat();
}
