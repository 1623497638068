<template>
	<div class="login">
		<a-col :xs="{ span: 12, offset: 6 }" :md="{ span: 6, offset: 15 }">
			<a-card title="员工登录">
				<a-form-model ref="loginFrom" :model="formData" :rules="loginRule">
					<a-form-model-item prop="organId" v-if="organList.length > 0">
						<a-select v-model="formData.organId">
							<a-icon type="ios-people" slot="prefix"></a-icon>
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
								organ.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item prop="username">
						<a-input type="link" v-model="formData.username" placeholder="请输入账户名称" :maxLength="20"
										 @pressEnter="handleSubmit('loginFrom')">
							<a-icon type="user" slot="prefix" />
						</a-input>
					</a-form-model-item>
					<a-form-model-item prop="password">
						<a-input type="password" v-model="formData.password" placeholder="请输入登录密码" :maxLength="30"
										 @pressEnter="handleSubmit('loginFrom')">
							<a-icon type="lock" slot="prefix"></a-icon>
						</a-input>
					</a-form-model-item>
					<a-form-model-item>
						<a-row :gutter="15">
							<a-col :span="12">
								<a-button type="default" block long @click="doUrl('/index')">返回首页</a-button>
							</a-col>
							<a-col :span="12">
								<a-button type="primary" block long @click="handleSubmit('loginFrom')" :loading="loading">立即登录</a-button>
							</a-col>
						</a-row>
					</a-form-model-item>
				</a-form-model>
			</a-card>
		</a-col>
		<Verify @success="captchaSuccess" mode="pop" captchaType="blockPuzzle" ref="verify"></Verify>
		<div class="footer" v-html="footer"></div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import Verify from '@/components/verifition/Verify';
import request from '@/config/request';
import utils from '@/common/utils';
import db from '@/common/localstorage';
export default {
	components: { Verify },
	name: 'login',
	data() {
		return {
			appid: '',
			loading: false,
			verifyCode: null,
			organList: [],
			formData: {
				organId: '',
				username: '',
				password: '',
				token: '',
			},
			footer: '',
			loginRule: {
				organId: [{ required: true, message: '请选择单位' }],
				username: [
					{ required: true, message: '请输入登录账号' },
					{ min: 3, max: 11, message: '请输入有效的账户信息' }
				],
				password: [
					{ required: true, message: '请输入登录密码' },
					{ min: 3, message: '请输入有效的登录密码' }
				]
			}
		};
	},
	mounted() {
		this.getOrganList();
		this.getFooter();
	},
	created() {
		this.db.clear();
		this.$router.options.routes = [];
	},
	methods: {
		captchaSuccess(data) {
			this.formData.token = data.captchaVerification;
			this.doSubmit();
		},
		// 用户名密码登录
		handleSubmit(name) {
			this.$refs[name].validate(res => {
				if (res) {
					this.$refs.verify.show();
				}
			});
		},
		// 获取底部信息
		getFooter() {
			request.get("/api/common/footer", {}).then(ret => {
				this.footer = ret.data.COPYRIGHTING;
			});
		},
		// 获取单位列表
		getOrganList() {
			request.get("/platform/login/organ").then(ret => {
				this.organList = ret.data;
				db.save("organ-list", ret.data);
				if (!utils.isEmpty(ret.data) && ret.data.length > 0 && this.formData.organId == '') {
					this.formData.organId = ret.data[0]['organId'];
				}
			});
		},
		doSubmit() {
			this.loading = true;
			this.utils.loading();
			this.http
				.post('/platform/login/staffLogin', this.formData)
				.then(ret => {
					console.log(ret);
					// this.loading = false;
					if (ret.code == 200) {
						this.saveLoginData(ret.data);
						return;
					}
					this.utils.error(ret.message);
				})
				.catch(err => {
					this.loading = false;
				});
		},
		...mapMutations({
			setToken: 'account/setToken',
			setPermissions: 'account/setPermissions',
			setRoles: 'account/setRoles',
			setUser: 'account/setUser'
		}),
		saveLoginData(data) {
			this.setToken(data.token);
			this.setUser(data);
			this.setPermissions(data.role);
			this.setRoles(data.menu);
			//登录成功
			this.utils.success('登录成功,正在进入系统....', 2).then(() => {
				window.location.href = '/system/main';
			});
		}
	}
};
</script>

<style scoped lang="less">
.login {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: url('../static/login-bg.jpg') no-repeat center center;
	background-size: cover;
	padding-top: 160px;
}

.buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	height: 80px;
	color: #fff;
	// box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
	width: 100%;
}
</style>
