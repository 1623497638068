<template>
    <a-input v-model="value" :disabled="record.options.disabled" :read-only="record.options.readonly"
             :placeholder="record.options.placeholder" />
</template>

<script>
import { exec, parse } from '@/common/calcUtil';
export default {
    data() {
        return {
            expression: '',//计算表达式
        };
    },
    mounted() {
        const replaceAll = (str, search, replacement) => {
            return str.replace(new RegExp(search, 'g'), replacement);
        };
        this.expression = replaceAll(this.record.options.formula, " ", "");
        // this.expression = this.record.options.formula.replaceAll(" ", "");
        // const expression = '(1 + (2 - (3 * 4 / 5 + 6 - (7 + 8))) + (9 - 10) * 11 + 12) + 13';
        // console.log(exec(parse(expression)));
        // console.log(exec(parse(expression)) === eval(expression));
        //监听数据变化
    },
    created() {
        app.$on('design-value-change', this.getValue);
    },
    methods: {
        getValue(key, value, values) {
            // console.log(key, value, values);
            if (this.record.options.disabled) {
                return;
            }
            if (this.expression.indexOf(key) < 0) {
                return;
            }
            const replaceAll = (str, search, replacement) => {
                return str.replace(new RegExp(search, 'g'), replacement);
            };
            let isCalc = true;//是否计算结果
            let exec_expression = this.expression;
            for (const k in values) {
                let v = values[k];
                if (k == key) {
                    v = value;
                }
                if (this.expression.indexOf(k) >= 0) {
                    if (isNaN(v) || v == undefined || v == null || v == '') {
                        v = 0;
                    }

                    exec_expression = replaceAll(exec_expression, k, v);
                    // exec_expression = exec_expression.replaceAll(k, v);
                }
            }
            console.log(isCalc, JSON.stringify(exec_expression));
            let vData = '';
            if (isCalc) {
                vData = eval(exec_expression);
                // console.log(vData)
            }
            if (vData) {
                vData = vData.toFixed(2);
            }
            this.$emit('change', vData);
            // console.log(exec_expression)
            // console.log(exec_expression + '=' + eval(exec_expression))
            // console.log(parse(exec_expression))
            // console.log(exec(parse(exec_expression)) === eval(expression));

        }
    },
    props: ["record", "value", "records"],
    destroyed() {
        app.$off('design-value-change');
    },
};
</script>

<style></style>