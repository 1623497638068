<template>
	<a-modal v-model="visible" :title="false" width="50%" :footer="null" @cancel="_close" centered destroyOnClose>
		<a-page-header bordered :ghost="false" :title="`汇报标题:${rowdata.title}`"></a-page-header>
		<div class="content">
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="汇报人">
					{{ rowdata.staffName }}
				</a-descriptions-item>
				<a-descriptions-item label="类型">
					{{ rowdata.type }}
				</a-descriptions-item>
				<a-descriptions-item label="汇报时间">
					{{ rowdata.createTime }}
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item :label="rowdata.type == '周报' ? '意见建议' : '进修健康状况'">{{ rowdata.reportFeedback }}</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item :label="rowdata.type == '周报' ? '重要业务' : '本月成绩'">{{ rowdata.reportMaster }}</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item :label="rowdata.type == '周报' ? '解决主要问题' : '目标情况'">{{ rowdata.reportQuestion }}</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item :label="rowdata.type == '周报' ? '进度与说明' : '构思建议'">{{ rowdata.reportRemark }}</a-descriptions-item>
			</a-descriptions>

			<a-tabs default-active-key="1" @change="callback">
				<a-tab-pane key="1" tab="内容">
					<div style="padding: 10px 0" v-html="rowdata.content"></div>
					<br />
					<!-- 附件 -->
					<a-descriptions size="small" v-if="rowdata.attachment" :column="3">
						<a-descriptions-item v-if="rowdata.attachment.length >= 1">
							<attachView :attachment="rowdata.attachment"></attachView>
						</a-descriptions-item>
					</a-descriptions>
				</a-tab-pane>
				<a-tab-pane key="2" tab="点评信息">
					<a-table :scroll="{ x: '100%' }" :style="{ background: '#FFF' }" size="small" :columns="columns" ref="list" :data-source="data" :loading="loading" :bordered="false" :pagination="false" row-key="id">
						<!--操作-->
						<template slot-scope="text, row, index" slot="action">
							<a-button-group shape="circle" size="small">
								<a-tooltip title="保存" v-if="row.edit">
									<a-button icon="check" @click="confirmRow(row, index)"></a-button>
								</a-tooltip>
								<a-tooltip title="取消" v-if="row.edit"><a-button icon="close" @click="delRow(row, index)"></a-button></a-tooltip>
								<a-tooltip title="删除" v-else><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
							</a-button-group>
						</template>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</div>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';

import attachView from '@/components/common/attach-view.vue';
export default {
	components: { attachView },
	data() {
		return {
			visible: false,
			loading: false,
			data: {
				title: '',
				content: '',
				createTime: ''
			},
			rowdata: {},
			reportId: '',
			columns: [
				{ title: '点评人', dataIndex: 'name', width: 160, align: 'center', ellipsis: true },
				{ title: '点评内容', dataIndex: 'content', width: 160, align: 'center', ellipsis: true }
			]
		};
	},
	methods: {
		...apiUtil,
		async show(row) {
			utils.showSpin();
			await this.http.post('/platform/work-report/detail/' + row.reportId).then(ret => {
				if (ret.code == 200) {
					this.rowdata = ret.data;
					this.reportId = ret.data.reportId;
				}
			});
			this.visible = true;
			this.getCommont();
		},
		getCommont() {
			this.http
				.post('/platform/work-report/comment/' + this.reportId)
				.then(ret => {
					if (ret.code == 200) {
						this.data = ret.data;
					}
				})
				.catch(err => {
					this.loading = false;
					console.log(err);
				});
		},
		_close() {
			this.visible = false;
		},
		callback(key) {
			if (key == 2) {
				this.getCommont();
			}
		}
	}
};
</script>

<style scoped>
.content {
	box-sizing: border-box;
	position: relative;
	padding: 16px 24px;
}
</style>
