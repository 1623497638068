import nowdate from './nowdate.vue';
export default {
	type: 'nowdate', // 表单类型
	label: '当前日期', // 标题文字
	icon: 'icon-gallery',
	component: nowdate,
	options: {
		width: '100%',
		readonly: false,
		disabled: false,
		showLabel: true,
		clearable: true,
		placeholder: '当前日期',
		format: 'yyyy年MM月DD日'
	},
	model: '',
	key: '',
	rules: []
};
