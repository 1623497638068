<template>
	<div class="containerq">
		<div class="time">
			<div class="date">{{ D }}</div>
			<div class="yarn">{{ Y }}/{{ M }}</div>
		</div>
		<div class="content">
			<div class="title">
				<div @click="$emit('clicktitle')">{{ data.title }}</div>
			</div>
			<div class="con" v-html="data.content"></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			D: '',
			M: '',
			Y: ''
		};
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		clicktitle: {
			type: Function
		}
	},
	mounted() {
		const ddd = this.data.createTime.split('-', 3);
		this.D = ddd[2].split(' ')[0];
		this.M = ddd[1];
		this.Y = ddd[0];

		if (document.documentMode) {
			this.wordlimit('con', 74);
		}
	},
	methods: {
		wordlimit(cname, wordlength) {
			var cname = document.getElementsByClassName(cname);
			// console.log(cname[0].innerHTML);
			for (var i = 0; i < cname.length; i++) {
				var nowhtml = cname[i].innerHTML;
				var nowlength = cname[i].innerHTML.length;
				if (nowlength > wordlength) {
					cname[i].innerHTML = nowhtml.substr(0, wordlength) + '…';
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.containerq {
	height: 70px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.time {
	width: 70px;
	height: 70px;
	background: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.date {
		color: #e96432;
		font-size: 24px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	flex: 1;

	.title {
		color: #e96432;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		/* 显示1行 */
		line-clamp: 1;
		/* 显示1行 */
	}

	.con {
		text-indent: 2em;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		/* 显示1行 */
		line-clamp: 2;
		/* 显示1行 */
	}
}

.img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}</style>
