<template>
	<a-button-group>
		<a-button type="primary" icon="ios-arrow-back" ghost @click="doUrl(backurl)" v-if="backurl != ''">返回</a-button>
		<a-button type="primary" v-has="btn.authorize" :icon="btn.icon" @click="doAction(btn)"
			v-for="(btn, index) in buttons" v-if="btn.isShow == 1" :key="index">{{ btn.name }}</a-button>
		<slot name="mySlot"></slot>
		<slot></slot>
	</a-button-group>
</template>

<script>
export default {
	name: 'CommonPageBtn',
	data() {
		return {
			buttons: []
		};
	},
	props: {
		backurl: {
			type: String,
			default: ''
		}
	},
	created() {
		this.buttons = this.$route.meta.buttons;
		// console.log(this.$route)
	},
	methods: {
		//按钮点击操作
		doAction(btn) {
			let actions = btn.authorize && btn.authorize != '' ? btn.authorize.split(':') : null;
			let action = actions && actions != null ? actions[actions.length - 1] : null;
			if (action) {
				console.log('执行：' + action + '回调');
				this.$emit(action);
			} else {
				console.log('事件没有找到回调方法！');
				console.log(btn);
			}
		}
	}
};
</script>

<style scoped></style>
