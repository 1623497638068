<template>
    <a-input v-model="data" :disabled="disabled" :read-only="readonly" />
</template>

<script>
import apiUtils from '@/common/apiUtil';
export default {
    data() {
        return {
            names: ''
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                if (this.value) {
                    return;
                }
                if (!this.disabled && !this.readonly) {
                    this.$emit('change', apiUtils.getUser('depName'))
                }
            }, 500);
        })
    },
    props: {
        record: {
            type: Object,
            default: () => {
                return {};
            }
        },
        value: {
            type: String,
            default: () => ""
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        readonly: {
            type: Boolean,
            default: () => false
        },
        multiple: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        data: {
            get() {
                return this.value;
            },
            set(e) {
                console.log(1111)
                if (!this.multiple) {
                    this.$emit('change', [e])
                } else {
                    this.$emit('change', e)
                }
            }
        }
    }
}
</script>

<style></style>