<template>
    <div style="width: 100%">
        <a-spin size="large" fix :spinning="loading">
        <a-upload style="width: 100%" :action="UPLOAD_URL" type="drag" :accept="accept"
                :headers="headers"
                :with-credentials="false"
                :before-upload="handleUpload"
                :on-success="upSuccess"
                :show-upload-list="false"
                :on-error="upError"
                  @change="upDone"
        >
            <template v-if="!img || img=='' || res==true">
                <a-icon type="ios-cloud-upload" size="52" style="color: #3399ff"></a-icon>
                <p>{{tipmsg}}</p>
                <p style="color: #ccc" v-if="imgText!=''">{{imgText}}</p>
            </template>
            <template v-else>
                <template v-if="isImg">
                    <img :src="img" style="width: 100%;" />
                </template>
                <template v-else>
                    <a-icon type="ios-document" size="52" style="color: #3399ff"></a-icon>
                    <p>重新上传</p>
                </template>
            </template>
        </a-upload>
        </a-spin>
    </div>
</template>

<script>
    import utils from "@/common/utils";
    import request, {getHeader, getUploadUrl} from "@/config/request";
    export default {
        data(){
            return{
                UPLOAD_URL:getUploadUrl(),
                headers:getHeader(),
                img:this.thumb,
                loading:false
            }
        },
        props:{
            accept:{
                type:String,
                default:'image/png,image/jpeg'
            },
            tipmsg:{
                type:String,
                default:'点击这里选择图片'
            },
            imgText:{
                type:String,
                default:''
            },
            thumb:{
                type:String,
                default:()=>{ return '';}
            },
            //需要带回的参数
            param:{
                type:Object|String,
                default:()=>{
                    return null;
                }
            },
            //是否只返回结果
            res:{
                type:Boolean,
                default:()=>{ return false;}
            }
        },
        watch:{
            thumb(val){
                this.img = val;
            }
        },
        computed:{
            isImg(){
                let suffix = utils.getSuffix(this.img);
                let imgSuffix = ['jpg','jpeg','png','gif'];
                console.log(imgSuffix.indexOf(suffix))
                if(imgSuffix.indexOf(suffix)>=0){
                    return true;
                }else{
                    return false;
                }
            }
        },
        methods:{
            //完成
            upDone(file,fileList,event){
                // console.log(file)
                if(file && file.file && file.file.response && file.file.response.code==200){
                    this.upSuccess("done",file.file);
                }
            },
            //文件上传之前
            handleUpload(file){
                this.loading = true;
            },
            upSuccess(event, file, fileList){
                this.loading = false;
                if(file.response.code==200){
                    this.img = file.response.data;
                    this.$emit('success',this.img,this.param);
                }else{
                    utils.error(file.response.message);
                }
            },
            //文件上传失败
            upError(response, file, fileList){
                this.loading = false;
                utils.error("文件上传失败！");
                console.log(response)
            }
        }
    }
</script>

<style scoped>

</style>
