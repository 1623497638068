import { nodeSchema, setFormDesignConfig } from '@/components/k-form-design/packages/mini';
import { useAntd } from '@/components/k-form-design/packages/core/useComponents';
import KFormDesign from '@/components/k-form-design/packages/use';
import '@/components/k-form-design/styles/k-form-design.less';

import title from './title/title';
import username from './username/username';
import depname from './depname/depname';
import rolename from './rolename/rolename';
import calc from './calc/calc';
import nowdate from './nowdate/nowdate';
import proof from './proof/proof';
import duties from './duties/duties';
import { formUploadConfig } from '@/config/request';

export default Vue => {
	setFormDesignConfig(formUploadConfig);
	// 添加组件
	nodeSchema.addSchemas([title, username, depname, rolename, calc, nowdate, proof, duties]);

	// 需要隐藏的组件删减掉就ok了
	nodeSchema.setSchemaGroup([
		{
			title: '基础组件',
			list: ['input', 'textarea', 'number', 'select', 'checkbox', 'radio', 'date', 'time', 'rate', 'slider', 'uploadImg', 'editor', 'switch', 'button', 'alert', 'text', 'html']
			// list: ['input', 'textarea', 'number', 'select', 'checkbox', 'radio', 'date', 'time', 'rate', 'slider', 'uploadFile', 'uploadImg', 'batch', 'editor', 'switch', 'button', 'alert', 'text', 'html']
		},
		{
			title: '布局组件',
			list: ['divider', 'grid', 'table']
			// list: ['divider', 'card', 'tabs', 'grid', 'table']
		},
		{
			title: '高级组件',
			list: ['title', 'username', 'depname', 'rolename', 'calc', 'nowdate', 'proof', 'duties']
		}
	]);

	useAntd(Vue);
	Vue.use(KFormDesign);
};
