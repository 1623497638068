import db from "./localstorage";

export const commonUtils = {

    /**
     * 生成随机字符串
     * @param len
     * @returns {string}
     */
    getRandStr(len) {
        len = len || 32;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz';
        /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },

	/**
	 * 空判断
	 * @param {Object} obj
	 */
	isEmpty(obj) {
		if (obj == null || obj == undefined || obj == '' || obj == 'undefined') {
			return true;
		}
		if (typeof obj == 'string' && obj.length == 0) {
			return true;
		}
		if (typeof obj == 'object' && Object.keys(obj).length == 0) {
			return true;
		}
		return false;
	},
}

