<template>
    <div id="app">
        <div class="loading" v-if="showLoading">
            <a-spin size="large" tip="Loading..."></a-spin>
        </div>
        <a-modal v-model="showImage" :title="false" :maskClosable="true" centered mask keyboard :width="500"
                 :footer="false">
            <img :src="img" style="width: 100%" />
        </a-modal>
        <router-view />
        <notice-detail ref="noticeDetail" />
        <news-detail ref="newsDetail" />
        <mail-deatil ref="mail"></mail-deatil>
        <plan-detail ref="planDetail"></plan-detail>
        <reportDetail ref="reportDetail"></reportDetail>
        <apply-detail ref="applyDetail"></apply-detail>
    </div>
</template>

<script>
import noticeDetail from './views/synthesize/notice/components/view-detail.vue';
import newsDetail from './views/synthesize/news/components/view-detail.vue';
import mailDeatil from './views/profile/mail/components/mailDetail.vue';
import planDetail from './views/profile/plan/components/detail.vue';
import reportDetail from './views/profile/report/components/detail.vue';
import applyDetail from './views/flow/work/components/apply-detail.vue';
export default {
    components: { noticeDetail, newsDetail, mailDeatil, planDetail, reportDetail, applyDetail },
    name: 'app',
    data() {
        return {
            showLoading: false,
            showImage: false,
            img: '',
        };
    },
    created() {
        this.initEvent();
    },
    mounted() {
        this.http.get("/api/common/footer", {}).then(ret => {
            window.document.title = ret.data.PLATFORM_NAME;
        });
    },
    methods: {
        //初始化全局事件
        initEvent() {
            //预览图片
            app.$on('open-image', img => {
                this.img = '';
                let t = img + '#t=' + Math.random();
                setTimeout(() => {
                    this.img = img;
                    this.showImage = true;
                }, 200);
            });
            //加载中
            app.$on('show-loading', (time) => {
                let s = (time == undefined || time == null || time < 1) ? 10 : time;
                this.showLoading = true;
                setTimeout(() => {
                    this.showLoading = false;
                }, s * 1000);
            });
            //关闭加载中
            app.$on('hide-loading', () => { this.showLoading = false; });
            //通知消息
            app.$on('notice-message', data => {
                // console.log(data);
                let title = '';
                let message = '';
                let uri = '';
                let key = '';
                let icon = '';
                let duration = 0;
                //新邮件通知
                if (data.type == 'EMAIL') {
                    key = data.type.toLowerCase() + '-message-' + data.data.id;
                    title = `新邮件通知`;
                    message = '您有新的未读邮件,请及时查看';
                    uri = '/profile/mail/receive';
                    icon = <a-icon type="mail" style="color: #108ee9" />;
                }
                //工作流
                if (data.type == 'WORK_FLOW') {
                    key = data.type.toLowerCase() + '-message-' + data.data;
                    title = '审批提醒';
                    message = '有新的工作事项需要您或您所在部门审批,请及时前往处理';
                    uri = '/flow/work/index';
                    icon = <a-icon type="form" style="color: #108ee9" />;
                }
                //工作计划
                if (data.type == 'WORK_PLAN') {
                    key = data.type.toLowerCase() + '-message-' + data.data.planId;
                    title = '工作计划提醒';
                    message = `${data.data.staffName}创建了新的工作计划,请及时查看`;
                    uri = '/profile/plan/query';
                    icon = <a-icon type="calendar" style="color: #108ee9" />;
                }
                //工作汇报
                if (data.type == 'WORK_REPORT') {
                    key = data.type.toLowerCase() + '-message-' + data.data.id;
                    title = '工作汇报提醒';
                    message = `${data.data.staffName}向您提交了工作汇报,请注意查收~`;
                    uri = '/profile/report/sub-list';
                    // duration = 5;
                    icon = <a-icon type="flag" style="color: #108ee9" />;
                }
                //用车申请
                if (data.type == 'CAR_APPLY') {
                    key = data.type.toLowerCase() + '-message-' + data.data.id;
                    title = '用车申请';
                    message = `${data.data.staffName}向您提交了${data.data.carNumber}的用车申请,请及时处理`;
                    uri = '/synthesize/car/record';
                    // duration = 5;
                    icon = <a-icon type="car" style="color: #108ee9" />;
                }
                this.$notification.info({
                    key: key,
                    message: title,
                    duration: duration,
                    description: message,
                    placement: 'bottomRight',
                    icon: icon,
                    onClick: () => {
                        this.$notification.close(key);
                        if (uri != '') {
                            this.doUrl(uri);
                        }
                    }
                });
            });
        }
    }
};
</script>

<style lang="less">
* {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.ivu-layout {
    background: #FFFFFF !important;
}

body,
html,
#app {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
}

.loading {
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content-header {
    padding: 0 0 20px 0;
    background: #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.rows {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.column {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.flex-center {
    justify-content: center;
    align-items: center;
}
</style>
