<template>
	<div class="container">
		<!-- 轮播图 -->
		<div class="banner">
			<a-carousel arrows class="my-carousel" dots-class="slick-dots slick-thumb" autoplay :after-change="onChange">
				<div v-for="banner in bannerList" :key="banner.id" class="box">
					<img style="width: 100%; height: 560px" :src="banner.src" alt="" />
				</div>
			</a-carousel>
		</div>
		<div class="home-container">
			<a-row :gutter="15">
				<!-- 通知公告 -->
				<a-col :span="8">
					<a-card title="通知公告" size="small" :bordered="false">
						<a-timeline>
							<template v-for="(item, index) in noticeList">
								<a-timeline-item :key="index" color="#e96432">
									<div class="notice-item">
										<div class="title">
											<a
												@click="doUrl('/index/notice/detail', { id: item.noticeId, title: item.title })">
												{{ item.title }}
											</a>
										</div>
										<div class="desc">
											<span v-html="item.content.substr(0, 60)"></span>
										</div>
										<div class="other">
											<span>发布时间:{{ item.createTime }}</span>
											<span>发布人：{{ item.staffName }}</span>
										</div>
									</div>
								</a-timeline-item>
							</template>
						</a-timeline>
					</a-card>
				</a-col>
				<!-- 新闻动态 -->
				<a-col :span="8">
					<a-card title="新闻动态" size="small" :bordered="false">
						<template v-for="(item, index) in newsList">
							<div :key="index" class="news-item"
								@click="doUrl('/index/news/detail', { id: item.newsId, title: item.title })"
								v-if="index < 3">
								<div class="thumb">
									<img :src="item.src" v-if="item.src != ''" />
									<span v-else>暂无图片</span>
								</div>
								<div class="info">
									<div class="title">
										<a>{{ item.title }}</a>
									</div>
									<div class="description">
										<div v-html="filterContent(item.content).substr(0, 75)"></div>
									</div>
								</div>
							</div>
						</template>
					</a-card>
				</a-col>

				<!-- 关于我们 -->
				<a-col :span="8">
					<a-card title="关于我们" size="small" :bordered="false">
						<span v-html="protocol.content.substr(0, 400)"></span>
						<a @click="doUrl('/index/introduce', { name: '公司简介' })">查看详情>></a>
					</a-card>
				</a-col>
			</a-row>
		</div>
	</div>
</template>
<script>
import utils from '@/common/utils';

import homeNews from './components/homeNews.vue';
export default {
	components: { homeNews },
	data() {
		return {
			bannerList: [],
			newsList: [],
			noticeList: [],
			protocol: { content: '' },
			notice: {}
		};
	},
	mounted() {
		this.getList();
		this.$nextTick(() => {
			this.wordlimit('ab-cont', 158);
		});
	},
	methods: {
		getList() {
			utils.showSpin();
			this.http
				.get('/api/common/all', { bannerId: 1, protocol: 'HOME_ABOUTUS' })
				.then(ret => {
					this.bannerList = ret.data.bannerList;
					this.newsList = ret.data.newsList;
					this.noticeList = ret.data.noticeList;
					this.protocol = ret.data.protocol;
					utils.closeSpin();
				})
				.catch(err => {
					utils.closeSpin();
				});
		},
		getNotice(id) {
			this.http.get(`/api/common/notice/detail/${id}`).then(ret => {
				this.notice = ret.data;
			});
		},

		onChange(a, b, c) {
			// console.log(a, b, c);
		},
		// 点击切换公告
		clicktime(e) {
			// console.log(e.target.dataset.id);
			this.getNotice(e.target.dataset.id);
		},
		// 点击查看公告详情
		noticedetail() {
			this.$router.push({
				path: '/index/notice/detail',
				query: {
					id: this.notice.noticeId,
					title: this.notice.title
				}
			});
		},
		// 点击通知公告查看更多
		clicknotice(e) {
			// console.log(e.target.innerText);
			if (e.target.innerText == '查看更多') {
				this.$router.push({
					path: '/index/notice'
				});
			}
		},
		// 点击前往新闻详情
		clicknews(item) {
			this.$router.push({
				path: '/index/news/detail',
				query: {
					id: item.newsId,
					title: item.title
				}
			});
		},
		// 点击新闻的查看更多
		clickmore() {
			this.$router.push({
				path: '/index/news'
			});
		},
		// 关于详情
		aboutdetail() {
			this.$router.push({
				path: '/index/introduce'
			});
		},
		filterContent(content) {
			// 使用正则表达式过滤掉图片标签和空标签
			let filtered = content.replace(/<img[^>]*>/g, '');
			filtered = filtered.replace(/<[^\/>][^>]*><\/[^>]+>/g, '');
			return filtered;
		},
		wordlimit(cname, wordlength) {
			var cname = document.getElementsByClassName(cname);
			for (var i = 0; i < cname.length; i++) {
				var nowhtml = cname[i].innerHTML;
				var nowlength = cname[i].innerHTML.length;
				console.log(cname[0].innerHTML);
				if (nowlength > wordlength) {
					cname[i].innerHTML = nowhtml.substr(0, wordlength) + '…';
				}
			}
		}
	}
};
</script>
<style scoped lang="less">
a {
	color: #e96432;
}

a:hover {
	color: rgba(#e96432, 0.7);
}

.banner {
	width: 100%;
	height: 580px;
	overflow: hidden;
}

.home-container {
	width: 80%;
	margin: 0 auto;

	// border: 1px solid #f00;
	.news-item {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #f7f7f7;
		cursor: pointer;

		.thumb {
			width: 60px;
			height: 60px;
			overflow: hidden;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background: #f7f7f7;
			font-size: 12px;
			color: #999;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.info {
			width: calc(100% - 80px);
			height: 60px;

			.title {
				font-size: 14px;
			}

			.description {
				font-size: 12px;
				color: #999;
			}
		}
	}

	.notice-item {
		width: 100%;
		display: flex;
		flex-direction: column;

		.title {
			font-size: 14px;
		}

		.desc {
			font-size: 12px;
			margin-top: 10px;
			color: #999;
		}

		.other {
			font-size: 12px;
			color: #999;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			span {
				margin-right: 10px;
			}
		}
	}
}</style>
