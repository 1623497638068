<template>
	<div>
		<div class="container">
			<Menu :openKey="openKey" :openKeys="'sub3'" @succ="success"></Menu>
			<div class="content">
				<a-breadcrumb class="nav" :routes="routes">
					<template slot="itemRender" slot-scope="{ route, routes }">
						<span class="active" v-if="routes.indexOf(route) === routes.length - 1">
							{{ route.breadcrumbName }}
						</span>
						<router-link v-else :to="`${basePath}/${route.path}`">
							{{ route.breadcrumbName }}
						</router-link>
					</template>
				</a-breadcrumb>
				<div style="margin-top: 20px">
					<div v-if="name == '公司简介'">
						<div class="intro" v-html="protocol.content"></div>
					</div>
					<div v-if="name == '组织机构'">
						<div class="intro" v-html="protocol.content"></div>
					</div>
					<div v-if="name == '发展历程'">
						<div class="intro" v-html="protocol.content"></div>
					</div>
					<div v-if="name == '企业荣誉'">
						<div class="intro" v-html="protocol.content"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import utils from '@/common/utils';
import Menu from '../components/menu.vue';

export default {
	components: { Menu },
	data() {
		return {
			protocol: '',
			name: '公司简介',
			openKey: '1',
			basePath: '/index',
			routes: [
				{
					path: 'home',
					breadcrumbName: '首页'
				},
				{
					path: 'introduce',
					breadcrumbName: '公司介绍'
				},
				{
					path: '',
					breadcrumbName: '公司简介'
				}
			]
		};
	},
	mounted() {
		if (this.$route.query.name) {
			this.name = this.$route.query.name;
			this.$set(this.routes[2], 'breadcrumbName', this.$route.query.name);
		}
		this.getList();
	},
	methods: {
		getList(protocol = 'COMPANY_PROFILE') {
			utils.showSpin();
			this.http
				.get('/api/common/all', { bannerId: 1, protocol })
				.then(ret => {
					// console.log(ret.data.protocol);
					this.protocol = ret.data.protocol;
					utils.closeSpin();
				})
				.catch(err => {
					utils.closeSpin();
				});
		},
		success(e) {
			this.name = e;
			this.routes[this.routes.length - 1].breadcrumbName = e;
		}
	},
	watch: {
		name(newv) {
			if (newv == '公司简介') {
				this.openKey = '1';
				this.$set(this.routes[2], 'breadcrumbName', newv);
				this.$router.push({
					path: '/index/introduce',
					query: {
						name: newv
					}
				});
				this.getList();
			}
			if (newv == '组织机构') {
				this.openKey = '2';
				this.$set(this.routes[2], 'breadcrumbName', newv);
				this.$router.push({
					path: '/index/introduce',
					query: {
						name: newv
					}
				});
				this.getList('ORGANIAZATION');
			}
			if (newv == '发展历程') {
				this.openKey = '3';
				this.$set(this.routes[2], 'breadcrumbName', newv);
				this.$router.push({
					path: '/index/introduce',
					query: {
						name: newv
					}
				});
				this.getList('HISTORY');
			}
			if (newv == '企业荣誉') {
				this.openKey = '4';
				this.$router.push({
					path: '/index/introduce',
					query: {
						name: newv
					}
				});
				this.$set(this.routes[2], 'breadcrumbName', newv);
				this.getList('ORGANIZATIONAL_GLORY');
			}
		},
		$route(to, from) {
			console.log(to);
			if (!to.query.name) {
				this.openKey = '1';
				this.$set(this.routes[2], 'breadcrumbName', '公司简介');
				this.getList();
			}
			if (to.query.name == '公司简介') {
				this.openKey = '1';
				this.$set(this.routes[2], 'breadcrumbName', to.query.name);
				this.getList();
			}
			if (to.query.name == '组织机构') {
				this.openKey = '2';
				this.$set(this.routes[2], 'breadcrumbName', to.query.name);
				this.getList('ORGANIAZATION');
			}
			if (to.query.name == '发展历程') {
				this.openKey = '3';
				this.$set(this.routes[2], 'breadcrumbName', to.query.name);
				this.getList('HISTORY');
			}
			if (to.query.name == '企业荣誉') {
				this.openKey = '4';
				this.$set(this.routes[2], 'breadcrumbName', to.query.name);
				this.getList('ORGANIZATIONAL_GLORY');
			}
		}
	}
};
</script>
<style scoped>
.container {
	width: 80%;
	min-width: 598px;
	margin: 0 auto;
	margin-top: 40px;
	display: flex;
}

.content {
	flex: 1;
	margin-left: 80px;
	height: 40px;
	background: #f7f7f7;
}
.nav {
	margin-top: 10px;
	margin-left: 10px;
}
.active {
	color: #e96432 !important;
}
.intro {
	text-indent: 2em;
}
.ant-breadcrumb a:hover {
	color: #e96432 !important;
}
.nav .ant-breadcrumb-link {
	color: #e96432;
}
.ant-breadcrumb {
	color: #e96432;
}
</style>
