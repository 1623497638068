import 'babel-polyfill';
import Vue from 'vue';
import App from './App';
import db from '@/common/localstorage';
import store from '@/common/store';
import router from '@/config/router';
import utils from '@/common/utils.js';
import request from '@/config/request';
import apiUtil from '@/common/apiUtil';
import theme from '@/static/echarts-theme';
import permissions from '@/config/permissions';
import VueECharts from 'vue-echarts';
import Moment from 'moment';
import 'moment/locale/zh-cn';
Vue.use(permissions);

import selectDepart from '@/components/common/select-depart.vue';
import selectStaff from '@/components/common/select-staff.vue';
import selectRole from '@/components/common/select-role';
import upImg from '@/components/common/upImg';
import CommonPageBtn from '@/components/component/CommonPageBtn';
import editor from '@/components/component/editor';
/* 富文本*/
import tinymce from 'tinymce';
import VueTinymce from '@packy-tang/vue-tinymce';
Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);
//二次封装
import myEditor from '@/components/tinymce';
Vue.component('my-editor', myEditor);
//表单设计组件
import custom from '@/components/custom-components/custom';
Vue.use(custom);

//流程组件
import '@/components/process/static/theme.less';
import '@/components/process/static/global.css';
import Ellipsis from '@/components/process/common/Ellipsis.vue';
import Print from '@/common/print';
import htmlToPdf from '@/common/htmlToPdf';
Vue.use(htmlToPdf);
Vue.use(Print);
Vue.use(Ellipsis);
Vue.config.productionTip = false;
Vue.prototype.$isNotEmpty = function (obj) {
	return obj !== undefined && obj !== null && obj !== '' && obj !== 'null';
};
Vue.prototype.$isEmpty = val => {
	return !val || val === '' || val === 'undefined' || val === 'null';
};
Vue.prototype.$getDefalut = function (obj, key, df) {
	return obj === undefined || key === undefined || !this.$isNotEmpty(obj[key]) ? df : obj[key];
};
Vue.prototype.$deepCopy = function (obj) {
	return JSON.parse(JSON.stringify(obj));
};

//全局组件
Vue.component('select-depart', selectDepart);
Vue.component('select-staff', selectStaff);
Vue.component('select-role', selectRole);
Vue.component('editor', editor);
Vue.component('chart', VueECharts);
Vue.component('up-img', upImg);
Vue.component('common-page-btn', CommonPageBtn);
// 定义全局时间戳过滤器 {{item.rateTime | formatDate}}
Vue.filter('formatDate', function (value) {
	return Moment(value).format('YYYY-MM-DD HH:mm:ss');
});
require('./common/ui');

Vue.prototype.db = db;
Vue.prototype.utils = utils;
Vue.prototype.apiUtil = apiUtil;
Vue.prototype.http = request;
Vue.prototype.echartsTheme = theme;
Vue.prototype.websocket = utils.websocket;
Vue.prototype.moment = Moment;
(function (undefined) { }).call(('object' === typeof window && window) || ('object' === typeof self && self) || ('object' === typeof global && global) || {});

//全局事件
window.app = new Vue();

/**
 * url跳转
 * @param {Object} url 跳转的页面<br>
 */
Vue.prototype.doUrl = function (url, param) {
	let path = '/' + url;
	let uri = path.replace('//', '/');
	if (uri == this.$route.path) {
		return;
	}
	this.$router.push({ path: uri, query: param }).then(r => { });
};

new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App)
});
