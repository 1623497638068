<template>
    <a-modal :visible="visible" width="90%" :title="false" :footer="null" centered @cancel="visible = false"
        :maskClosable="false">
        <div class="document-container">
            <div class="menu">
                <a-menu style="border: none;">
                    <template v-if="isEdit">
                        <a-menu-item @click="doSave()">
                            <a-icon type="save"></a-icon>
                            <span>保存文档</span>
                        </a-menu-item>
                        <a-menu-item @click="doSetting">
                            <a-icon type="setting"></a-icon>
                            <span>页面设置</span>
                        </a-menu-item>
                    </template>
                    <a-menu-item @click="doPrint">
                        <a-icon type="printer"></a-icon>
                        <span>打印文档</span>
                    </a-menu-item>
                    <a-menu-item @click="doCheckSign">
                        <a-icon type="check-circle"></a-icon>
                        <span>验证签名</span>
                    </a-menu-item>
                    <a-menu-item @click="doHandSign">
                        <a-icon type="highlight"></a-icon>
                        <span>手写签名</span>
                    </a-menu-item>
                    <a-menu-item @click="doHandDraw">
                        <a-icon type="bg-colors"></a-icon>
                        <span>手工绘图</span>
                    </a-menu-item>
                    <a-menu-item @click="doFullScreenHandSign">
                        <a-icon type="edit"></a-icon>
                        <span>全屏手写签名</span>
                    </a-menu-item>
        
                    <!-- <a-menu-item @click="doPrintPrivew">
                        <a-icon type="printer"></a-icon>
                        <span>打印预览</span>
                    </a-menu-item> -->
                    <!-- <a-menu-item @click="startTrack">
                        <a-icon type="close"></a-icon>
                        <span>显示痕迹</span>
                    </a-menu-item>
                    <a-menu-item @click="closeTrack">
                        <a-icon type="close"></a-icon>
                        <span>隐藏痕迹</span>
                    </a-menu-item> -->
                    <a-menu-item @click="doClose">
                        <a-icon type="close-circle"></a-icon>
                        <span>关闭页面</span>
                    </a-menu-item>

                </a-menu>
            </div>
            <div class="document">
                <object id="TANGER_OCX" classid="clsid:C9BC4DFF-4248-4a3c-8A49-63A7D317F404"
                    codebase="officecontrol.cab#version=5,0,2,1" width="100%" height="100%">
                    <param name="ProductCaption" value="贵州西南国际家居装饰博览城有限公司" />
                    <param name="ProductKey" value="B0FE708C223419544AEA024F7A317CB9E657C9BD" />
                    <PARAM NAME="TitlebarTextColor" VALUE="0" />
                    <PARAM NAME="BorderStyle" VALUE="1" />
                    <PARAM NAME="Titlebar" VALUE="1" />
                    <PARAM NAME="Toolbars" VALUE="1" />
                    <PARAM NAME="Caption" VALUE="Office文档在线编辑" />
                    <PARAM NAME="IsShowToolMenu" VALUE="0" />
                    <PARAM NAME="IsNoCopy" VALUE="0" />
                    <PARAM NAME="IsHiddenOpenURL" VALUE="0" />
                    <PARAM NAME="MaxUploadSize" VALUE="0" />
                    <PARAM NAME="Menubar" VALUE="1" />
                    <PARAM NAME="Statusbar" VALUE="1" />
                    <PARAM NAME="FileNew" VALUE="0" />
                    <PARAM NAME="FileOpen" VALUE="0" />
                    <PARAM NAME="FileClose" VALUE="0" />
                    <PARAM NAME="FileSave" VALUE="0" />
                    <PARAM NAME="FileSaveAs" VALUE="0" />
                    <PARAM NAME="FilePrint" VALUE="-1" />
                    <PARAM NAME="FilePrintPreview" VALUE="-1" />
                    <PARAM NAME="FilePageSetup" VALUE="0" />
                    <PARAM NAME="FileProperties" VALUE="0" />
                    <PARAM NAME="IsStrictNoCopy" VALUE="0" />
                    <PARAM NAME="IsUseUTF8URL" VALUE="0" />
                    <PARAM NAME="MenubarColor" VALUE="-2147483643" />
                    <PARAM NAME="IsUseControlAgent" VALUE="0" />
                    <PARAM NAME="IsUseUTF8Data" VALUE="0" />
                    <PARAM NAME="IsSaveDocExtention" VALUE="0" />
                    <PARAM NAME="IsDirectConnect" VALUE="0" />
                    <PARAM NAME="SignCursorType" VALUE="0" />
                    <PARAM NAME="IsResetToolbarsOnOpen" VALUE="0" />
                    <PARAM NAME="IsSaveDataIfHasVDS" VALUE="0" />
                    <SPAN STYLE="color:red">不能装载文档控件。请在检查浏览器的选项中检查浏览器的安全设置。</SPAN>
                </object>
            </div>

        </div>
    </a-modal>
</template>

<script>
import request, { apiUrl, getHeader, getUploadUrl } from "@/config/request";
import utils from '@/common/utils';
export default {
    data() {
        return {
            visible: false,
            doc: null,
            isEdit: false,
            fileName: '',
            user: {}
        }
    },
    mounted() {
        this.user = this.$store.state.account.user;
    },
    methods: {
        show(url, edit, data) {
            console.log(url)
            this.isEdit = edit;
            this.visible = true;
            this.getFileName(url);
            this.$nextTick(() => {
                this.initDoc(url, edit);
            })
        },
        // 计算文件名
        getFileName(url) {
            if (url == '' || url == undefined || url == null) {
                this.fileName = 'newFile';
                return;
            }
            const parts = url.split('/');
            this.fileName = parts.slice(4).join('/');
        },
        // 关闭
        doClose() {
            if (this.doc) {
                this.doc.close();
            }
            this.visible = false;
        },
        // 打印
        doPrint() {
            if (this.doc == null) {
                utils.error('文档未加载成功'); return;
            }
            this.doc.printout(true);
        },
        // 打印
        doPrintPrivew() {
            if (this.doc == null) {
                utils.error('文档未加载成功'); return;
            }
            this.doc.PrintPreview();
        },
        // 页面设置
        doSetting() {
            if (this.doc == null) {
                utils.error('文档未加载成功'); return;
            }
            this.doc.showdialog(5);
        },
        // 保存
        doSave() {
            if (this.doc == null) {
                utils.error('文档未加载成功'); return;
            }
            const url = apiUrl + 'platform/common/saveFile';
            this.doc.savetourl(url, "EDITFILE", getHeader(), this.fileName, 0, false);
            utils.success('保存成功')
        },
        initDoc(docUrl, edit) {
            const ntkoobj = document.getElementById('TANGER_OCX');
            if (ntkoobj == null) {
                alert("文档控件未能正确装载,请在本页面装饰窗口上方出现的提示条上点击安装ActiveX空间,并在随后出现的安装ActiveX的对话框中选择'是'");
                return;
            }
            if (ntkoobj.activeDocument === undefined) {
                return;
            }
            if (docUrl) {
                ntkoobj.BeginOpenFromURL(docUrl, true, !edit);
            } else {
                ntkoobj.CreateNew('Word.Document');
            }
            // ntkoobj.FileSave = true;
            window.ntkoobj = ntkoobj;
            this.doc = ntkoobj;
        },
        //开启痕迹
        startTrack() {
            if (this.doc == null) {
                return;
            }
            this.doc.activeDocument.TrackRevisions = true
            this.doc.activeDocument.application.UserName = this.user.depName + '-' + this.user.name
        },
        //关闭痕迹
        closeTrack() {
            if (this.doc == null) {
                return;
            }
            this.doc.activeDocument.TrackRevisions = false;
            this.doc.activeDocument.application.UserName = ""
        },
        //全屏手写签名
        doFullScreenHandSign(){
              this.doc.DoHandSign2(
                this.user.name,//当前登陆用户 必须	
                '',
                0,//left//可选参数
                0,//top
                0,//relative=0，表示按照屏幕位置批注
                100 //缩放100%，表示原大小
                ); 
        },
        //手写签名
        doHandSign(){	
            this.doc.DoHandSign(
            this.user.name,//当前登陆用户 必须
            0,//笔型0－实线 0－4 //可选参数
            0x000000ff, //颜色 0x00RRGGBB//可选参数
            2,//笔宽//可选参数
            100,//left//可选参数
            50,//top//可选参数
            false,//可选参数
            ''
            ); 
        },
        //检查签名结果
        doCheckSign(){
            let ret = this.doc.DoCheckSign(
                    false,/*如果为FAlSE，表示弹出验证对话框,否则，只是返回验证结果到返回值*/
                    ''
                )
            console.log(ret)
        },
        //手工绘图
        doHandDraw(){
            this.doc.DoHandDraw(
            0,//笔型0－实线 0－4 //可选参数
            0x00ff0000,//颜色 0x00RRGGBB//可选参数
            3,//笔宽//可选参数
            200,//left//可选参数
            50);//top//可选参数
        }

    }
}
</script>

<style lang="less">
.document-container {
    width: 100%;
    height: 85vh;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .menu {
        width: 140px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }

    .document {
        width: calc(100% - 160px);
        background: #f7f7f7;
    }
}
</style>