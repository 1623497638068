import proof from './proof.vue';
export default {
	type: 'proof', // 表单类型
	label: '文号编号', // 标题文字
	icon: 'icon-gallery',
	component: proof,
	options: {
		defaultValue: '',
		showLabel: false,
		orientation: 'center',
		addonBefore: ''
	},
	model: '',
	key: ''
};
