import username from './username.vue';
export default {
	type: 'username', // 表单类型
	label: '操作人', // 标题文字
	icon: 'icon-gallery',
	component: username,
	options: {
		defaultValue: '',
		width: '100%',
		readonly: false,
		disabled: false,
		showLabel: true,
		clearable: true,
		placeholder: '操作人'
	},
	model: '',
	key: '',
	rules: [{ required: false, message: '请填写操作人' }]
};
