<template>
	<div class="menu">
		<a-menu style="width: 256px" :default-open-keys="[openKeys]" :default-selected-keys="[openKey]" mode="inline"
			@click="handleClick" :selectedKeys="[openKey]">
			<!-- <a-sub-menu key="sub1"> -->
			<a-menu-item key="5">首页</a-menu-item>
			<!-- <a-sub-menu key="sub3" title="公司介绍" @titleClick="titleClick"> -->
			<a-menu-item v-if="name != ''" key="6">{{ name }}</a-menu-item>
			<a-menu-item key="1">公司简介</a-menu-item>
			<a-menu-item key="2">组织机构</a-menu-item>
			<a-menu-item key="3">发展历程</a-menu-item>
			<a-menu-item key="4">企业荣誉</a-menu-item>
			<!-- </a-sub-menu> -->

			<!-- <a-menu-item key="6">
				企业文化
			</a-menu-item>
			<a-menu-item key="7">
				新闻信息
			</a-menu-item>
			<a-menu-item key="12">
				通知公告
			</a-menu-item>
			<a-menu-item key="8">
				招商信息
			</a-menu-item>
			<a-menu-item key="9">
				家居服务
			</a-menu-item>
			<a-menu-item key="10">
				人力资源
			</a-menu-item>
			<a-menu-item key="11">
				党建工作
			</a-menu-item> -->
		</a-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		openKeys: {
			type: String,
			default: () => {
				return '';
			}
		},
		openKey: {
			type: String,
			default: () => {
				return '';
			}
		},
		name: {
			type: String,
			default: () => {
				return '';
			}
		}
	},
	methods: {
		handleClick(e) {
			if (e.key == '1') {
				this.$router.push({ path: '/index/introduce', query: { name: '公司简介' } });
			}
			if (e.key == '2') {
				this.$router.push({ path: '/index/introduce', query: { name: '组织机构' } });
			}
			if (e.key == '3') {
				this.$router.push({ path: '/index/introduce', query: { name: '发展历程' } });
			}
			if (e.key == '4') {
				this.$router.push({ path: '/index/introduce', query: { name: '企业荣誉' } });
			}
			if (e.key == '6') {
				this.$router.push({ path: '/index/culture' });
			}
			if (e.key == '7') {
				this.$router.push({ path: '/index/news' });
			}
			if (e.key == '8') {
				this.$router.push({ path: '/index/attract' });
			}
			if (e.key == '9') {
				this.$router.push({ path: '/index/furnishings' });
			}
			if (e.key == '10') {
				this.$router.push({ path: '/index/resources' });
			}
			if (e.key == '11') {
				this.$router.push({ path: '/index/party' });
			}
			if (e.key == '5') {
				this.$router.push({ path: '/index/home' });
			}
			if (e.key == '12') {
				this.$router.push({ path: '/index/notice' });
			}
		},
		titleClick(e) {
			if (e.key == 'sub3') {
				this.$router.push({ path: '/index/introduce' });
			}
		}
	}
};
</script>

<style scoped>
.ant-menu-item-selected {
	color: #e96432 !important;
	background: rgba(215, 181, 163, 0.3) !important;
}

.ant-menu-inline .ant-menu-item::after {
	border-right-color: #e96432 !important;
}

.ant-menu-item:hover,
.ant-menu-submenu-selected {
	color: #e96432 !important;
}

.ant-menu-submenu-active {
	color: #e96432 !important;
}

.menu /deep/ .ant-menu-submenu-title:hover {
	color: #e96432 !important;
}

.menu /deep/ .ant-menu-submenu-arrow::before,
.menu /deep/ .ant-menu-submenu-arrow::after {
	background: linear-gradient(to right, #e96432, #e96432) !important;
}</style>
