<template>
  <div style="margin-top: 20px;">
    <div>请选择审批人：</div>
    <a-select show-search placeholder="请选择审批人" option-filter-prop="children" @change="$emit('success', value)"
              v-model="value" style="width: 200px" :filter-option="filterOption" @focus="handleFocus" @search="search">
      <a-spin v-if="fetching" slot="notFoundContent" size="small" />
      <a-select-option v-for="item in data" :key="item.staffId" :value="item.staffId">
        {{ item.name }}
      </a-select-option>

    </a-select>
  </div>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
export default {
  data() {
    return {
      data: [],
      value: '',
      keyword: '',
      fetching: false,
      timeoutId: null,
    };
  },
  created() {
    this.$emit('focus');
  },
  mounted() {
  },
  methods: {
    // 搜索
    search(e) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.keyword = e;
        this.getData();
      }, 200); // 设置节流时间为 200ms
    },
    // 获取数据
    getData() {
      this.fetching = true;
      request.post('/platform/apply/getStaffList', { flowId: this.flowId, formData: this.formData, keyword: this.keyword }).then(ret => {
        if (ret.data.list.length == 1) {
          this.value = ret.data.list[0].staffId;
          this.$emit('success', this.value);
        }
        this.data = ret.data.list;
        this.fetching = false;
      });
    },
    // 获得焦点
    handleFocus() {
      this.$emit('focus');
      setTimeout(() => {
        if (!this.formData) {
          utils.error("请确认表单是否完成");
          return;
        }
        this.getData();
      }, 200);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

  },
  props: {
    formData: {
      type: String,
      default: () => {
        return null;
      }
    },
    flowId: {
      type: Number,
      default: () => {
        return null;
      }
    },
  }
};
</script>

<style lang="less"></style>