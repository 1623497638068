<template>
	<div class="container">
		<div class="header">
			<Header></Header>
		</div>
		<div style="padding-bottom: 120px;">
			<router-view></router-view>
		</div>
		<div class="login" @click="login">
			<span>登录</span>
		</div>
		<div class="footer" v-html="footer"></div>
	</div>
</template>

<script>
import request from '@/config/request';
import Header from './components/Header.vue';
export default {
	components: { Header },
	name: 'Index',
	data() {
		return {
			footer: ''
		};
	},
	mounted() {
		this.getFooter();
	},
	methods: {
		login() {
			this.$router.push({
				path: '/login'
			});
		},
		getFooter() {
			request.get("/api/common/footer", {}).then(ret => {
				this.footer = ret.data.COPYRIGHTING;
			});
		}
	}
};
</script>

<style scoped lang="less">
.container {
	width: 100%;
	// height: 100%;
	// box-sizing: border-box;
}

.login {
	cursor: pointer;
	position: fixed;
	bottom: 50px;
	right: 50px;
	color: #fff;
	background: #e96432;
	width: 50px;
	height: 50px;
	text-align: center;
	// line-height: 50px;
	border-radius: 50%;
	z-index: 99;
	font-size: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	height: 80px;
	background-color: #fff;
	// box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
	width: 100%;
	border-top: 1px solid #f7f7f7;
}

.record {
	padding: 10px 0;
	/* 设置备案号内边距 */
	text-align: center;
	font-size: 14px;
	color: #666;
	line-height: 15px;

	span {
		margin-left: 15px;
	}
}

.container /deep/ .ant-pagination-item-active {
	border-color: #e96432 !important;
}

.container /deep/ .ant-pagination-item-active a {
	color: #e96432 !important;
}

.container /deep/ .ant-pagination-item:hover {
	border-color: #e96432 !important;
}

.container /deep/ .ant-pagination-item:hover a {
	color: #e96432 !important;
}

.container /deep/ .ant-pagination-prev:hover .ant-pagination-item-link,
.container /deep/ .ant-pagination-next:hover .ant-pagination-item-link {
	border-color: #e96432 !important;
	color: #e96432 !important;
}

.container /deep/ .ant-pagination-prev:hover a,
.container /deep/ .ant-pagination-next:hover a {
	color: #e96432 !important;
}
</style>
