<template>
    <a-date-picker :default-value="moment(utils.formatDate(new Date(), format), format)" :valueFormat="format"
        @change="onChange" :format="format" v-model="a" :disabled="disabled" />
</template>

<script>
import utils from '@/common/utils';
export default {
    data() {
        return {
            nowdate: '',
            a: ''
        };
    },
    watch: {
        format(newv) {
            this.$emit('change', utils.formatDate(new Date(), newv));
        },
        value(newv) {
            this.a = newv;
        }
    },
    methods: {
        onChange(e) {
            this.$emit('change', e.format(this.format));
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                if (this.value) {
                    return;
                }
                if (!this.disabled && !this.readonly) {
                    this.$emit('change', utils.formatDate(new Date(), this.format));
                }
            }, 500);
        });
    },
    props: {
        value: {
            type: String,
            default: () => ""
        },
        format: {
            type: String,
            default: () => "yyyy年MM月DD日"
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        readonly: {
            type: Boolean,
            default: () => false
        },
        multiple: {
            type: Boolean,
            default: () => true
        }
    }
};
</script>

<style></style>