var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.record.options.noFormItem)?_c(_vm.componentItem,_vm._b({tag:"component",on:{"hook:mounted":_vm.childMounted,"change":_vm.handleChange}},'component',_vm.getComponentProps,false)):_c('a-form-item',{style:(_vm.formConfig.layout === 'horizontal' &&
  _vm.formConfig.labelLayout === 'flex' &&
  _vm.isShowLabel(_vm.record.options.showLabel)
  ? { display: 'flex' }
  : {}),attrs:{"label-col":_vm.formConfig.layout === 'horizontal' &&
  _vm.isShowLabel(_vm.record.options.showLabel)
  ? _vm.formConfig.labelLayout === 'flex'
    ? { style: `width:${_vm.formConfig.labelWidth}px` }
    : _vm.formConfig.labelCol
  : {},"wrapper-col":_vm.formConfig.layout === 'horizontal' &&
  _vm.isShowLabel(_vm.record.options.showLabel)
  ? _vm.formConfig.labelLayout === 'flex'
    ? { style: 'width:auto;flex:1' }
    : _vm.formConfig.wrapperCol
  : {}}},[(_vm.isShowLabel(_vm.record.options.showLabel))?_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('a-tooltip',[_c('span',{domProps:{"textContent":_vm._s(_vm.record.label)}}),(_vm.record.help)?_c('span',{attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.record.help)},slot:"title"}):_vm._e(),(_vm.record.help)?_c('a-icon',{staticClass:"question-circle",attrs:{"type":"question-circle-o"}}):_vm._e()],1)],1):_vm._e(),_c(_vm.componentItem,_vm._b({directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model, // input 的 name
      {
        initialValue: _vm.record.options.defaultValue, // 默认值
        valuePropName: _vm.record.type === 'switch' ? 'checked' : 'value',
        rules: _vm.getRules // 验证规则
      }
    ]),expression:"[\n      record.model, // input 的 name\n      {\n        initialValue: record.options.defaultValue, // 默认值\n        valuePropName: record.type === 'switch' ? 'checked' : 'value',\n        rules: getRules // 验证规则\n      }\n    ]"}],ref:"inputItem",tag:"component",on:{"hook:mounted":_vm.childMounted,"change":_vm.handleChange}},'component',_vm.getComponentProps,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }