<template>
    <div id="app">
        <a-modal
                :visible="show"
                title="修改登录密码"
                :width="400"
                :destroyOnClose="true"
                @ok="doSubmit"
                @cancel="_close">
            <a-form-model ref="form" :model="formData" :rules="formRule">
                <a-row :gutter="8">
                    <a-col span="24">
                        <a-form-model-item label="新密码" label-position="top" prop="password">
                            <a-input type="password" v-model="formData.password" placeholder="请输入新的登录密码">
                                <a-icon type="ios-lock-outline" slot="prepend"></a-icon>
                            </a-input>
                        </a-form-model-item>

                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import request from "@/config/request";
    import utils from "@/common/utils";

    export default {
        data() {
            return {
                show: false,
                formData: {
                    password:''
                },
                formRule: {
                    password: [
                        {required: true, message: '请输入新密码'},
                        {min:5,message: '密码长度至少5位'}
                    ]
                }
            }
        },
        methods: {
            _show() {
                this.show = true;
            },
            _close() {
                this.show = false;

            },
            doSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        utils.loading();
                        request.get('/platform/info/reset-password', this.formData).then(ret => {
                            if (ret.code == 200) {
                                utils.success('操作成功！').then(() => {
                                    this.$refs.form.resetFields();
                                    this.show = false;
                                    this.$emit('success');
                                })
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                })
            }
        }
    }
</script>
