import axios from 'axios';
import { message, Modal } from 'ant-design-vue';
import store from '@/common/store';
import utils from '@/common/utils';
import aesUtils from '@/common/aesUtils';
import { saveAs } from 'file-saver';

export let serverUrl, apiUrl, socketUrl;

//是否开启websocket
export const websocket_status = true;
//环境配置
export const mode = 'prod';
switch (mode) {
	case 'prod':
		serverUrl = 'gateway.xinanjiaju.com';
		apiUrl = 'https://' + serverUrl + '/';
		socketUrl = 'wss://' + serverUrl + '/websocket';
		break;
	case 'test':
		serverUrl = 'service.xinan.dev.kintreda.com';
		apiUrl = 'https://' + serverUrl + '/';
		socketUrl = 'wss://' + serverUrl + '/websocket';
		break;
	default:
		serverUrl = '192.168.196.10:8020';
		apiUrl = 'http://' + serverUrl + '/';
		socketUrl = 'ws://' + serverUrl + ':10002/';
		break;
}

/**
 * 上传头
 */
export const getHeader = () => {
	let header = {};
	if (store.state.account.token) {
		header.Authorization = store.state.account.token;
	}
	header.sign = aesUtils.encrypt(Date.now());
	return header;
};
/**
 * 上传地址
 * @returns {string}
 */
export const getUploadUrl = () => {
	return apiUrl + 'platform/info/upload';
};
/**
 * 上传附件地址
 * @returns {string}
 */
export const UpLoadUrl = () => {
	return apiUrl + 'platform/info/upload/attach';
};

/**
 * 表单设计上传接口
 * 要求响应状态不一样,0=成功
 */
export const formUploadConfig = {
	uploadFile: apiUrl + 'platform/info/formUpload', // 上传文件地址
	uploadImage: apiUrl + 'platform/info/formUpload', // 上传图片地址
	uploadFileName: 'file', // 上传文件name
	uploadImageName: 'file', // 上传图片name
	uploadFileData: {}, // 上传文件额外参数
	uploadImageData: {}, // 上传图片额外参数
	uploadFileHeaders: getHeader(), // 上传文件请求头部
	uploadImageHeaders: getHeader() // 上传图片请求头部
};

// 统一配置
let ADMIN_REQUEST = axios.create({
	baseURL: apiUrl,
	responseType: 'json',
	validateStatus(status) {
		// 200 外的状态码都认定为失败
		return status === 200;
	}
});

// 拦截请求
ADMIN_REQUEST.interceptors.request.use(
	config => {
		config.headers = getHeader();
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// 拦截响应
ADMIN_REQUEST.interceptors.response.use(
	response => {
		message.destroy();
		utils.closeSpin();
		//下载文件返回数据判断
		if (response.data && response.data.type) {
			if (response.data.type == 'application/octet-stream' || response.data.type == 'application/force-download') {
				return response.data;
			}
			if (response.data.type == 'application/json') {
				let reader = new FileReader();
				reader.readAsText(response.data, 'utf-8');
				reader.onload = e => {
					let error = JSON.parse(reader.result);
					message.error({
						content: error.message + ' code：-' + error.code,
						duration: 2
					});
				};
				throw new Error(response.data);
			}
		}
		//登录身份过期
		if (response.data && response.data.code == 10000) {
			message.error({
				content: '登录身份过期，请重新登录！',
				duration: 2,
				onClose: () => {
					window.location.href = '/login';
				}
			});
			return;
		}
		if (response.data && response.data.code && response.data.code != 200) {
			Modal.error({
				centered: true,
				// content: response.data.message + ' \r\n code：-' + response.data.code,
				title: response.data.message,
				content: 'code：-' + response.data.code,
				duration: 2
			});
			return Promise.reject(response.data);
		} else {
			return response.data;
		}
	},
	error => {
		// nProgress.done();
		utils.closeAll();
		utils.closeSpin();
		utils.error(error.message);
		return Promise.reject(error);
	}
);
/**
 * 编辑器文件上传
 * @param file
 * @param Editor
 * @param cursorLocation
 * @param resetUploader
 */
export const editorUpload = (file, Editor, cursorLocation, resetUploader) => {
	let formData = new FormData();
	formData.append('file', file);
	utils.loading();
	return ADMIN_REQUEST.post(getUploadUrl(), formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
		.then(ret => {
			utils.closeAll();
			//把获取到的图片url 插入到鼠标所在位置 回显图片
			Editor.insertEmbed(cursorLocation, 'image', aesUtil.decrypt(ret.data, null));
			resetUploader();
		})
		.catch(err => {
			utils.closeAll();
		});
};

export const doUp = file => {
	let formData = new FormData();
	formData.append('file', file);
	utils.loading();
	return ADMIN_REQUEST.post(getUploadUrl(), formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};
const request = {
	apiUrl,
	socketUrl,
	post(url, params) {
		return ADMIN_REQUEST.post(url, params);
	},
	put(url, params) {
		return ADMIN_REQUEST.put(url, params, {
			transformRequest: [
				params => {
					let result = '';
					Object.keys(params).forEach(key => {
						if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
							result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
						}
					});
					return result;
				}
			],
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	},
	get(url, params) {
		let _params;
		if (Object.is(params, undefined)) {
			_params = '';
		} else {
			_params = '?';
			for (let key in params) {
				if (params.hasOwnProperty(key) && params[key] !== null) {
					_params += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
				}
			}
		}
		return ADMIN_REQUEST.get(`${url}${_params}`);
	},
	delete(url, params) {
		let _params;
		if (Object.is(params, undefined)) {
			_params = '';
		} else {
			_params = '?';
			for (let key in params) {
				if (params.hasOwnProperty(key) && params[key] !== null) {
					_params += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
				}
			}
		}
		return ADMIN_REQUEST.delete(`${url}${_params}`);
	},
	export(url, params = {}) {
		utils.loading();
		return ADMIN_REQUEST.post(url, params, {
			transformRequest: [
				params => {
					let result = '';
					Object.keys(params).forEach(key => {
						if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
							result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
						}
					});
					return result;
				}
			],
			responseType: 'blob'
		}).then(r => {
			const blob = new Blob([r]);
			const fileName = `${new Date().getTime()}_导出结果.xlsx`;
			if ('download' in document.createElement('a')) {
				const elink = document.createElement('a');
				elink.download = fileName;
				elink.style.display = 'none';
				elink.href = URL.createObjectURL(blob);
				document.body.appendChild(elink);
				elink.click();
				URL.revokeObjectURL(elink.href);
				document.body.removeChild(elink);
			} else {
				navigator.msSaveBlob(blob, fileName);
			}
		});
	},
	download(url, params, filename) {
		utils.loading();
		return ADMIN_REQUEST.post(url, params, {
			transformRequest: [
				params => {
					let result = '';
					if (!utils.isEmpty(params)) {
						Object.keys(params).forEach(key => {
							if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
								result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&';
							}
						});
					}
					return result;
				}
			],
			timeout: 600000,
			responseType: 'blob'
			// responseType: 'arraybuffer'
		}).then(r => {
			console.log(r);
			const mimeType = utils.getMimeType(filename);
			const blob = new Blob([r], utils.isEmpty(mimeType) ? {} : { type: mimeType });
			saveAs(blob, filename);
			return;
			if ('download' in document.createElement('a')) {
				const elink = document.createElement('a');
				elink.download = filename;
				elink.style.display = 'none';
				elink.href = URL.createObjectURL(blob);
				document.body.appendChild(elink);
				elink.click();
				URL.revokeObjectURL(elink.href);
				document.body.removeChild(elink);
			} else {
				navigator.msSaveBlob(blob, filename);
			}
		});
	},
	upload(params) {
		return ADMIN_REQUEST.post(getUploadUrl(), params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}
};

export default request;
