<template>
	<div>
		<div class="container">
			<Menu :openKey="'6'" :name="'人力资源'"></Menu>
			<div class="content">
				<a-breadcrumb class="nav" :routes="routes">
					<template slot="itemRender" slot-scope="{ route, routes }">
						<span class="active" v-if="routes.indexOf(route) === routes.length - 1">
							{{ route.breadcrumbName }}
						</span>
						<router-link v-else :to="`${basePath}/${route.path}`">
							{{ route.breadcrumbName }}
						</router-link>
					</template>
				</a-breadcrumb>
				<div style="margin: 20px 0">
					<div v-if="isRouter">
						<Job v-for="item in data.records" :key="item.jobId" :data="item" @success="success(item)"></Job>
						<a-row>
							<a-col span="12" offset="6" style="text-align: center; padding: 20px 0">
								<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit" :current="data.current" />
							</a-col>
						</a-row>
					</div>
					<router-view v-if="!isRouter"></router-view>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import utils from '@/common/utils';
import Menu from '../components/menu.vue';
import Job from './components/job.vue';
export default {
	components: { Menu, Job },
	data() {
		return {
			isRouter: true,
			data: {},
			param: {
				current: 1,
				limit: 10,
				keyword: ''
			},
			basePath: '/index',
			routes: [
				{
					path: 'home',
					breadcrumbName: '首页'
				},
				{
					path: 'resources',
					breadcrumbName: '人力资源'
				}
			]
		};
	},
	created() {
		this.$watch('$route', (to, from) => {
			if (to.path) {
				this.isRouter = true;
				if (to.path == '/index/resources') {
					this.routes.pop();
				}
			}
		});
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			utils.showSpin();
			this.http.get('/api/common/job', this.param).then(ret => {
				utils.closeSpin();
				this.data = ret.data;
			});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		async success(item) {
			await this.$router.push({
				path: '/index/resources/detail',
				query: {
					id: item.jobId
				}
			});
			this.isRouter = false;
			this.routes.push({
				path: 'resources/detail',
				breadcrumbName: item.title
			});
		}
	}
};
</script>
<style scoped>
.container {
	min-width: 598px;
	width: 80%;
	margin: 0 auto;
	margin-top: 40px;
	display: flex;
}

.content {
	flex: 1;
	margin-left: 80px;
	height: 40px;
	background: #f7f7f7;
}
.nav {
	margin-top: 10px;
	margin-left: 10px;
}
.active {
	color: #e96432 !important;
}
.ant-breadcrumb a:hover {
	color: #e96432 !important;
}
.nav .ant-breadcrumb-link {
	color: #e96432;
}
.ant-breadcrumb {
	color: #e96432;
}
</style>
