import Index from "@/views/index/index.vue";
import Home from "@/views/index/home.vue";
import Introduce from "@/views/index/Introduce";
import Culture from "@/views/index/Culture";
import News from "@/views/index/News";
import Attract from "@/views/index/Attract";
import Furnishings from "@/views/index/Furnishings";
import Resources from "@/views/index/Resources";
import Party from "@/views/index/Party";
import Notice from "@/views/index/Notice";
export default
  {
    path: '/index',
    component: Index,
    redirect: '/index/home',
    children: [
      // 首页
      {
        path: '/index/home',
        name: "Index",
        component: Home
      },
      // 公司介绍
      {
        path: '/index/introduce',
        name: 'Introduce',
        component: Introduce
      },
      // 企业文化
      {
        path: '/index/culture',
        name: 'Culture',
        component: Culture,
        children: [{
          path: '/index/culture/detail',
          name: 'Culturedetail',
          component: () => import("@/views/index/Culture/detail.vue")
        }]
      },
      // 新闻
      {
        path: '/index/news',
        name: 'News',
        component: News,
        children: [{
          path: '/index/news/detail',
          name: 'newsdetail',
          component: () => import("@/views/index/News/detail.vue")
        }]
      },
      // 通知公告
      {
        path: '/index/notice',
        name: 'Notice',
        component: Notice,
        children: [{
          path: '/index/notice/detail',
          name: 'noticedetail',
          component: () => import("@/views/index/Notice/detail.vue")
        }]

      },
      // 招商
      {
        path: '/index/attract',
        name: 'Attract',
        component: Attract,
        children: [{
          path: '/index/attract/detail',
          name: 'attractdtail',
          component: () => import("@/views/index/Attract/detaile.vue")
        }]
      },
      // 家居服务
      {
        path: '/index/furnishings',
        name: 'Furnishings',
        component: Furnishings,
        children: [{
          path: '/index/furnishings/detail',
          name: 'furnishingsdtail',
          component: () => import("@/views/index/Furnishings/components/detail.vue")
        }]
      },
      // 人力资源
      {
        path: '/index/resources',
        name: 'Resources',
        component: Resources,
        children: [{
          path: '/index/resources/detail',
          name: 'resourcesdtail',
          component: () => import("@/views/index/Resources/components/detaile.vue")
        }]
      },
      // 党建工作
      {
        path: '/index/party',
        name: 'Party',
        component: Party,
        children: [{
          path: '/index/party/detail',
          name: 'party',
          component: () => import("@/views/index/Party/detaile.vue")
        }]
      },
    ]
  }

