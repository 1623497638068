<template>
	<a-modal v-model="visible" :title="false" width="50%" :footer="null" @cancel="_close" centered destroyOnClose>
		<a-page-header bordered :ghost="false" :title="`计划名称：${rowdata.title}`">
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="负责人">
					{{ rowdata.managerName }}
				</a-descriptions-item>
				<a-descriptions-item label="状态">
					{{ rowdata.status }}
				</a-descriptions-item>
				<a-descriptions-item label="分类名称">
					{{ rowdata.categoryName }}
				</a-descriptions-item>
				<a-descriptions-item label="开始时间">
					{{ rowdata.startDate }}
				</a-descriptions-item>
				<a-descriptions-item label="结束时间">
					{{ rowdata.overDate }}
				</a-descriptions-item>
				<a-descriptions-item label="完成百分比">
					<div style="width:90px"><Progress :percent="rowdata.process"></Progress></div>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="参与人">{{ rowdata.staffNames }}</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="开放范围">{{ rowdata.openScope }}</a-descriptions-item>
			</a-descriptions>
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="备注">{{ rowdata.remark }}</a-descriptions-item>
			</a-descriptions>
		</a-page-header>
		<div class="content">
			<a-tabs default-active-key="1" @change="callback">
				<a-tab-pane key="1" tab="内容">
					<div style="padding: 10px 0;" v-html="rowdata.content"></div>
					<br />
					<!-- 附件 -->
					<a-descriptions size="small" v-if="rowdata.attachment" :column="3">
						<a-descriptions-item v-if="rowdata.attachment.length >= 1">
							<attachView :attachment="rowdata.attachment"></attachView>
						</a-descriptions-item>
					</a-descriptions>
				</a-tab-pane>
				<a-tab-pane key="2" tab="日志" force-render>
					<logs-table ref="logs" :planId="rowdata.planId"></logs-table>
				</a-tab-pane>
				<a-tab-pane key="3" tab="领导批注">
					<comment-table ref="comment" :planId="rowdata.planId"></comment-table>
				</a-tab-pane>
			</a-tabs>
		</div>
	</a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import attachView from '@/components/common/attach-view.vue';
import Progress from './progress.vue';
import commentTable from './commentTable.vue';
import logsTable from './logsTable.vue';

import utils from '@/common/utils';
export default {
	components: { attachView, Progress, commentTable, logsTable },
	data() {
		return {
			visible: false,
			loading: false,
			data: {
				title: '',
				content: '',
				createTime: ''
			},
			rowdata: {}
		};
	},
	methods: {
		...apiUtil,
		async show(row) {
			utils.showSpin();
			await this.http.post('/platform/work-plan/detail/' + row.planId).then(ret => {
				if (ret.code == 200) {
					// console.log(ret.data);
					this.rowdata = ret.data;
				}
			});
			this.visible = true;
		},
		_close() {
			this.visible = false;
		},
		callback(key) {
			if (key == 2) {
				this.$refs.logs.getList();
			}
			if (key == 3) {
				if (this.$refs.comment != undefined) {
					this.$refs.comment.getList();
				}
			}
		}
	}
};
</script>

<style scoped>
.content {
	box-sizing: border-box;
	position: relative;
	padding: 16px 24px;
}
</style>
