<template>
	<div>
		<a-table :scroll="{ x: '100%' }" :style="{ background: '#FFF' }" size="small" :columns="columns" ref="list"
						 :data-source="data.records" :loading="loading" :bordered="false" :pagination="false" row-key="id">
			<template slot-scope="row" slot="content">
				<div v-html="row.content" @click="detailCon(row.content)"></div>
			</template>
			<!-- 附件 -->
			<template slot-scope="row" slot="attachment">
				<attachView :istitle="false" :attachment="row.attachment"></attachView>
			</template>
		</a-table>
		<a-row>
			<a-col offset="2" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="total" @change="getNext" @showSizeChange="setLimit"
											:current="param.page" />
			</a-col>
		</a-row>
		<a-modal :visible="visible" @ok="visible = false" @cancel="visible = false">
			<div v-html="ModalText"></div>
		</a-modal>
	</div>
</template>
<script>
import attachView from '@/components/common/attach-view.vue';

export default {
	components: { attachView },
	data() {
		return {
			visible: false,
			ModalText: '',
			loading: false,
			data: {},
			total: 0,
			param: {
				page: 1,
				limit: 5,
				keyword: ''
			},
			columns: [
				{ title: '点评人', dataIndex: 'staffName', width: 100, align: 'center' },
				{ title: '点评内容', scopedSlots: { customRender: 'content' }, width: 300 },
				{ title: '附件', scopedSlots: { customRender: 'attachment' }, align: 'left' }
			]
		};
	},
	mounted() {
		this.getList();
	},
	props: ['planId'],
	methods: {
		detailCon(content) {
			this.ModalText = content;
			this.visible = true;
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get(`/platform/work-plan/annotation/${this.planId}`, this.param)
				.then(ret => {
					if (ret.code == 200) {
						this.data = ret.data;
						this.total = ret.data.total;
						this.loading = false;
					}
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style></style>
