<template>
  <a-select v-model="data" :disabled="disabled" @change="handleSelectChange">
    <a-select-option v-for="item in nams" :key="item.roleId" :value="item.name">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>
import apiUtils from '@/common/apiUtil';
export default {
  data() {
    return {
      names: '',
      nams: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.value) {
          return;
        }
        if (!this.disabled && !this.readonly) {
          this.$emit('change', apiUtils.getUser('roleName'));
          this.nams = apiUtils.getUser('sysRoles');
        }
      }, 500);
    })
  },
  methods: {
    handleSelectChange(value) {
      // 当 a-select 组件的选项发生改变时触发
      // console.log('选项改变:', value);

      // 触发 k-form-design 的事件，将选择的值传递给表单设计器
      this.$emit('input', value);
    }
  },
  props: {
    value: {
      type: String,
      default: () => ""
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(e) {
        if (!this.multiple) {
          this.$emit('change', [e]);
        } else {
          this.$emit('change', e);
        }
      }
    }
  }
};
</script>

<style></style>