<template>
	<div class="layout">
		<!-- 顶部区域 -->
		<a-layout :class="{ 'hide-nav': !fullscreens }" id="components-layout-fixed">
			<a-layout-header :style="{
				padding: 0,
				position: 'fixed',
				top: '0',
				zIndex: 999,
				width: '100%',
			}">
				<div class="logo">
					<!-- <div>测试</div> -->
					{{ name }}
					<!-- <img src="@/static/logo.png" /> -->
				</div>
				<!-- 顶部导航 -->
				<a-menu class="menu" mode="horizontal" theme="dark" :default-selected-keys="[topMenuId]"
								:style="{ lineHeight: '64px' }">
					<template v-for="item in menu">
						<a-menu-item :style="{ padding: '0 20px' }" :key="item.menuId" v-if="item.isShow == 1">
							<div class="click" @click="clickTop(item)">
								<a-icon :type="item.icon"></a-icon>
								<span>{{ item.name }}</span>
							</div>
						</a-menu-item>
					</template>
				</a-menu>
				<div class="layout-login">
					<div class="user-info" href="javascript:void(0)">
						<div class="user-depart">
							<div class="name">
								<span>{{ user.name }}</span>
							</div>
							<div class="depname" v-if="user.organName">
								<span>{{ user.organName }}</span>
								<span>-</span>
								<span>{{ user.depName }}</span>
							</div>
						</div>
						<a-dropdown :trigger="['hover']" :transfer="true">
							<div>
								<a-avatar icon="user"></a-avatar>
							</div>
							<a-menu slot="overlay">
								<a-menu-item name="password" @click="avatarAction('password')">
									<a-icon type="lock"></a-icon>修改密码
								</a-menu-item>
								<a-menu-item name="logout" @click="avatarAction('logout')">
									<a-icon type="logout"></a-icon>退出登录
								</a-menu-item>
							</a-menu>
						</a-dropdown>
					</div>

				</div>
			</a-layout-header>
		</a-layout>
		<!-- 底部区域 -->
		<a-layout :style="{
			minHeight: 'calc(100vh - 64px)',
			width: '100%',
			position: 'fixed',
			top: !fullscreens ? '0px' : '65px',
		}">
			<!-- 左侧菜单 -->
			<a-layout-sider theme="light" :class="{ 'hide-nav': !fullscreens }" v-model="isCollapsed" collapsible>
				<a-menu theme="light" :default-selected-keys="leftMenuId" :default-open-keys="leftMenuId" mode="inline">
					<template v-for="item in leftMenu">
						<!-- 有子菜单 -->
						<a-sub-menu :name="item.menuId" :key="item.menuId" v-if="item.isShow == 1 && item.menuNum > 0">
							<span slot="title">
								<a-icon v-show="item.icon" :type="item.icon" />
								<span>{{ item.name }}</span>
							</span>
							<!--子菜单-->
							<template v-for="obj in item.children">
								<!-- 有子菜单 -->
								<a-sub-menu :name="obj.menuId" :key="obj.menuId" v-if="obj.isShow == 1 && obj.menuNum > 0">
									<span slot="title">
										<a-icon v-show="obj.icon" :type="obj.icon" />
										<span>{{ obj.name }}</span>
									</span>
									<!--子菜单-->
									<template v-for="sub in obj.children">
										<a-menu-item :key="sub.menuId"
																 @click="toNavUrl(sub.url, item.menuId + '-' + obj.menuId + '-' + sub.menuId, item.parentId)">
											<div class="click">
												<a-icon v-if="sub.icon" :type="sub.icon"></a-icon>
												<a-icon v-else type="right"></a-icon>
												<span>{{ sub.name }}</span>
											</div>
										</a-menu-item>
									</template>
								</a-sub-menu>
								<!--没有子菜单-->
								<a-menu-item :key="obj.menuId" @click="toNavUrl(obj.url, item.menuId + '-' + obj.menuId, item.parentId)"
														 v-else>
									<div class="click">
										<a-icon v-if="obj.icon" :type="obj.icon"></a-icon>
										<a-icon v-else type="right"></a-icon>
										<span>{{ obj.name }}</span>
									</div>
								</a-menu-item>
							</template>
						</a-sub-menu>
						<!-- 没有子菜单 -->
						<a-menu-item :name="item.menuId" :key="item.menuId" @click="toNavUrl(item.url, item.menuId, item.parentId)"
												 v-show="item.isShow == 1" v-else>
							<div class="click">
								<a-icon v-show="item.icon" :type="item.icon"></a-icon>
								<span>{{ item.name }}</span>
							</div>
						</a-menu-item>
					</template>
				</a-menu>
			</a-layout-sider>
			<!-- 右侧内容区域 -->
			<a-layout :style="{ height: 'calc(100vh - 65px)', position: 'relative' }">
				<a-layout-content class="layout-content" :style="{
					padding: '20px',
					height: '100%',
					position: 'relative',
				}">
					<a-card :bordered="false" :dis-hover="true">
						<router-view />
					</a-card>
				</a-layout-content>
			</a-layout>
		</a-layout>
		<set-password ref="setPwd"></set-password>
	</div>
</template>

<script>
import { mapState } from "vuex";
import SetPassword from "@/views/SetPassword";
import utils from "@/common/utils";
import db from '@/common/localstorage.js';
import apiUtils from "@/common/apiUtil";
import canvas from '@/common/water_canvas';
import request from '@/config/request';
import { websocket_status } from '@/config/request';
export default {
	name: "layout",
	components: { SetPassword },
	data() {
		return {
			leftMenu: [],
			topMenuId: 0,
			leftMenuId: [0],
			isCollapsed: false,
			fullscreens: true,
			name: ''
		};
	},
	computed: {
		...mapState({
			user: (state) => state.account.user,
			menu: (state) => state.account.roles,
			platform: (state) => state.account.platform,
		}),
	},
	created() {
		this.initUrl();
		this.createWater();
	},
	mounted() {
		if (websocket_status) {
			this.utils.websocket.init();
		}
		this.http.get("/api/common/footer", {}).then(ret => {
			this.name = ret.data.PLATFORM_NAME;
		});
		app.$on('fullscreen', (e) => {
			console.log(e);
			if (e) {
				this.fullscreens = true;
			} else {
				this.fullscreens = !this.fullscreens;
			}


		});
	},
	methods: {

		...apiUtils,

		createWater() {
			const text = this.user.depName ? this.user.depName + '-' + this.user.name : this.user.roleName + '-' + this.user.name;
			canvas({
				content: text,
				width: 320,
				height: 220
			});
		},
		initUrl() {
			let p = db.get('pId');
			if (this.utils.isEmpty(p)) {
				this.setDefaultMenu(0, 0); return;
			}
			this.topMenuId = parseInt(p);
			//左侧菜单数据
			this.leftMenu = getLeftMenuList(this.topMenuId, this.menu);
			function getLeftMenuList(topMenuId, list) {
				let data = null;
				list.map(menu => {
					if (menu.menuId == topMenuId) {
						data = menu;
					}
				});
				return data.children;
			}
			//左侧菜单选中
			let path = db.get('leftId') + "";
			let ids = path.split("-");
			this.leftMenuId = [];
			ids.forEach(element => {
				this.leftMenuId.push(parseInt(element));
			});
		},
		//右上角操作
		avatarAction(e) {
			if (e == "logout") {
				utils.confirm("确认要退出登录吗?").then((ret) => {
					db.clear();
					utils.websocket.close();
					window.location.href = "/login";
				});
				return;
			}
			if (e == "password") {
				this.$refs.setPwd._show();
			}
		},
		//设置默认菜单
		setDefaultMenu(index, childrenIndex) {
			let defaultMenu = this.menu[index];
			this.topMenuId = defaultMenu.menuId;
			this.leftMenu = defaultMenu.children;
			this.leftMenuId = [defaultMenu.children[childrenIndex]["menuId"]];
		},
		//点击顶部菜单事件
		clickTop(data) {
			this.leftMenu = data.children;
			this.topMenuId = data.menuId;
			// this.leftMenuId = this.leftMenu[0]['menuId'];
			// this.doUrl(this.leftMenu[0]['url'])
		},
		toNavUrl(url, m, p) {
			db.save('leftId', m);
			db.save('pId', p);
			this.doUrl(url);
		}
	},
};
</script>

<style lang="less" scoped>
@import "../static/theme.less";

#components-layout-fixed .logo {
	min-width: 200px;
	padding: 0 10px;
	height: 64px;
	float: left;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 24px;

	>img {
		height: (100%-2px);
	}
}

.hide-nav {
	display: none !important;
}

#components-layout-fixed .menu {
	width: calc(100% - 320px - 300px);
	float: left;
}

#components-layout-fixed .layout-login {
	// width: 300px;
	float: right;
	text-align: right;
	padding-right: 20px;
	color: #fff;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.user-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.user-depart {
		height: 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		padding: 0 10px;

		.name {
			line-height: normal;
			font-size: 14px;
		}


		.depname {
			line-height: normal;
			font-size: 12px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			color: @primary-5;
		}
	}
}

/*内容区域*/
.layout-content {
	background: #f5f7f9;
	/*background: #f8f8f8;*/
	overflow: auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.layout-content::-webkit-scrollbar {
	display: none;
}

.click {
	width: 100%;
	height: 100%;
}
</style>
