<template>
	<div class="attachment" v-if="!utils.isEmpty(attachment)">
		<div class="title" v-if="istitle">
			<a-icon type="link" />
			<span style="margin-left: 10px; font-size: 14px">附件</span>
		</div>
		<div class="attach-list">
			<template>
				<div class="item" v-for="(item, index) in attachment" :key="index">
					<a-tooltip title="下载文件" v-if="!utils.isEmpty(item.url)">
						<a :href="item.url" target="_blank">{{ item.name }}</a>
					</a-tooltip>
					<span style="color: #f00; text-decoration: line-through" v-else>{{ item.name }}(不可用)</span>
					<a-tooltip title="预览" v-if="utils.isImageFile(item.name) || utils.isWordOrExcelFile(item.name)">
						<a-button @click="eye(item)" type="link" icon="eye"></a-button>
					</a-tooltip>
				</div>
			</template>
		</div>
		<office ref="office"></office>
	</div>
</template>
<script>
/**
 * 附件显示组件
 * example <attach-view :attachment="data.attachment"></attach-view>
 */
import utils from '@/common/utils';
import office from '@/components/office/document';
export default {
	components: { office },
	data() {
		return {};
	},
	methods: {
		// 预览
		eye(item) {
			if (utils.isWordOrExcelFile(item.name)) {
				this.$refs.office.show(item.url, 0);
			} else if (utils.isImageFile(item.name)) {
				utils.openImage(item.url);
			} else {
				return;
			}
		}
	},
	props: {
		attachment: {
			type: Array || '',
			default: () => {
				return [];
			}
		},
		istitle: {
			type: Boolean,
			default: () => {
				return true;
			}
		}
	},
	watch: {
		attachment(v) {
			console.log(v);
		}
	}
};
</script>

<style>
.attachment {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 16px;
	font-weight: bold;
}

.attach-list {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}

.item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 10px;
	margin-right: 20px;
}
</style>
