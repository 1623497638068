<template>
    <div class="title">
        {{ record.label }}
    </div>
</template>

<script>
export default {
    name: 'k-title',
    props: ["record"],
    mounted() {
        // console.log(this.record);
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}
</style>