/**
 * 审批流程表单工具类
 */
import utils from './utils';
const formUtils = {
	/**
	 * 获取有权限更改的数据
	 * 只读字段和隐藏的字段将会被删除
	 * @param {Object} data 所有的数据
	 * @param {Array} readList 只读字段列表
	 * @param {Array} hideList  隐藏字段列表
	 * @returns
	 */
	getData(data, readList, hideList) {
		if (!utils.isEmpty(data)) {
			for (let key in data) {
				if (readList.indexOf(key) >= 0 || hideList.indexOf(key) >= 0) {
					if(data[key]==undefined || data[key]==null || data[key]=='' ){
						delete data[key];
					}
				}
			}
		}
		return data;
	},

	/**
	 * 检查禁用字段
	 * 把禁用和隐藏掉的字段去除必填
	 * @param {Object} formJson
	 * @param {Array} readList 只读字段列表
	 * @param {Array} hideList 隐藏字段列表
	 * @returns
	 */
	checkDisable(formJson, readList, hideList) {
		if (formJson && formJson.list) {
			formJson.list.forEach((item, i) => {
				if (item.columns) {
					item.columns.forEach(child => {
						child = formUtils.checkDisable(child, readList, hideList);
					});
				}
				//未设置默认值
				if (item.options.maxLength === '' && item.options.maxLength === null) {
					item.options.maxLength = 10000;
				}
				//如果只读、隐藏属性，不校验表单
				if ((readList.indexOf(item.key) >= 0 || hideList.indexOf(item.key) >= 0) && item.rules) {
					item.rules.forEach(rule => {
						if (rule.required) {
							rule.required = false;
						}
					});
				}
			});
		}
		return formJson;
	},
	/**
	 * 获取字段规则
	 * 获取只读字段和隐藏字段
	 * @param {Array} formRule
	 * @returns
	 */
	getFieldsRuleList(formRule) {
		const readList = []; //只读字段
		const hideList = []; //隐藏字段
		if (!utils.isEmpty(formRule)) {
			formRule.forEach(item => {
				if (item.perm == 'R') {
					readList.push(item.id);
				}
				if (item.perm == 'H') {
					hideList.push(item.id);
				}
			});
		}
		return { hideList: hideList, readList: readList };
		// if (hideList.length > 0) {
		// 	this.$refs.kfb.hide(hideList);
		// }
		// if (readList) {
		// 	this.$refs.kfb.disable(readList);
		// }
		// this.readList = readList;
		// this.hideList = hideList;
	}
};
export default formUtils;
