import rolename from './rolename.vue';
export default {
	type: 'rolename', // 表单类型
	label: '角色', // 标题文字
	icon: 'icon-gallery',
	component: rolename,
	options: {
		defaultValue: '',
		width: '100%',
		disabled: false,
		readonly: false,
		showLabel: true,
		clearable: true,
		placeholder: '角色'
	},
	model: '',
	key: '',
	rules: [{ required: false, message: '请填写角色' }]
};
