/**
 * 全局默认数据
 */
import utils from './utils';
import { commonUtils } from './common-utils';
import db from './localstorage';
import request from '@/config/request';
const apiUtils = {
	/**
	 * 获取用户信息
	 */
	getUser(field) {
		const user = db.get('USER');
		if (commonUtils.isEmpty(user)) {
			return null;
		}
		if (!commonUtils.isEmpty(field) && !commonUtils.isEmpty(user[field])) {
			return user[field];
		} else if (!commonUtils.isEmpty(field)) {
			return null;
		} else {
			return user;
		}
	},
	/**
	 * 是否登录
	 */
	isLogin() {
		const u = this.getUser();
		return !commonUtils.isEmpty(u);
	},
	/**
	 * 是否管理员
	 */
	isAdmin() {
		const u = this.getUser();
		if (commonUtils.isEmpty(u.admId) || u.admId < 1) {
			return false;
		} else {
			return true;
		}
	},
	// 获取单位ID
	getOrganId(defaultValue) {
		const organId = this.getUser('organId');
		if (commonUtils.isEmpty(organId)) {
			return defaultValue != undefined ? defaultValue : 0;
		} else {
			return organId;
		}
	},
	// 获取默认单位ID
	getDefaultOrganId() {
		if (this.getOrganId() > 0) {
			return this.getOrganId();
		}
		const list = this.getOrganList();
		if (list.length > 0) {
			return list[0]['organId'];
		} else {
			return 0;
		}
	},
	// 获取默认单位名称
	getDefaultOrganName(organId) {
		let id = organId ? organId : this.getDefaultOrganId();
		const list = this.getOrganList();
		let res = '';
		for (let i = 0; i < list.length; i++) {
			let item = list[i];
			if (item.organId == id) {
				res = item.name;
				break;
			}
		}
		return res;
	},
	// 获取单位列表
	getOrganList() {
		const list = db.get('organ-list');
		if (!commonUtils.isEmpty(list)) {
			const organId = this.getOrganId();
			if (organId <= 10000) {
				return list;
			}
			let result = [];
			list.forEach(item => {
				if (item.organId == organId) {
					result.push(item);
				}
			});
			return result;
		} else {
			return [];
		}
	},
	getAllOrganList() {
		return db.get('organ-list');
	}
};

export default apiUtils;
