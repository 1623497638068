<template>
	<a-modal :visible="visible" title="添加计划日志" @ok="handleOk" @cancel="handleCancel" :width="800">
		<a-form-model ref="ruleForm" :model="formDate" :rules="rules">
			<a-form-model-item label="完成百分比" prop="process">
				<a-input min="1" max="100" type="Number" v-model="formDate.process" placeholder="请输入完成百分比"></a-input>
			</a-form-model-item>
			<a-form-model-item label="日志内容" prop="content">
				<!-- <a-textarea v-model="formDate.content"></a-textarea> -->
				<editor v-model="formDate.content" @input="e => (formData.content = e)"></editor>
			</a-form-model-item>
			<a-form-model-item>
				<attach-upload @input="e => (formDate.attachment = e)"></attach-upload>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import selectStaff from '@/components/common/select-staff.vue';
import utils from '@/common/utils';
import attachUpload from '@/components/common/attach-upload.vue';
import attachView from '@/components/common/attach-view.vue';
import editor from '@/components/component/editor.vue';
import Progress from './progress.vue';

export default {
	components: { selectStaff, attachUpload, attachView, editor, Progress },
	data() {
		return {
			visible: false,
			data: [],
			editcontent: '',
			formDate: {
				id: '',
				content: '',
				attachment: [],
				planId: '',
				process: ''
			},
			rules: {
				process: [{ required: true, message: '请输入百分比' }],
				content: [{ required: true, message: '请输入日志详情' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(row) {
			this.formDate.planId = row.planId;
			if (row.id) {
				this.formDate.id = row.id;
				this.formDate.content = row.content;
				this.formDate.process = row.process;
			} else {
				this.formDate.content = '';
				this.formDate.process = '';
			}
			this.visible = true;
		},
		// 确定添加
		handleOk() {
			this.$refs.ruleForm.validate(valid => {
				if (valid) {
					this.http
						.post('/platform/work-plan/logs/save', this.formDate)
						.then(ret => {
							if (ret.code == 200) {
								this.utils.success('操作成功！').then(() => {
									this.visible = false;
									this.$emit('success');
								});
							}
						})
						.catch(err => {
							this.visible = false;
							this.utils.error('操作失败！').then(() => {
								console.log(err);
							});
						});
				} else {
					return false;
				}
			});
		},

		handleCancel() {
			this.visible = false;
		}
	}
};
</script>

<style scoped>
.content {
	box-sizing: border-box;
	position: relative;
	padding: 0 24px;
}

.container {
	font-size: 20px;
}

.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
