<template>
    <div v-if="data != null">
        <a-modal v-model="visible" :title="data.title" :width="formJson.config.width > 800 ? formJson.config.width : 800"
            :footer="null" @cancel="close()" centered destroyOnClose>
            <a-tabs defaultActiveKey="form" v-model="activeKey">
                <a-tab-pane key="form" tab="表单">
                    <div id="pdfDom" :class="{ 'downClass': isDown }" ref="form">
                        <k-form-build ref="kfb" :value="formJson" :disabled="data.isAuth == false" />
                        <attach-view :attachment="data.attachment"></attach-view>
                        <div style="margin-top: 20px;" v-if="isTime">
                            <div ref="timeline" v-for="(item, index) in data.process" :key="index">
                                <a-timeline-item color="green">
                                    <div class="process">
                                        <div class="title">{{ item.flowName }}</div>
                                        <div v-if="!item.isRoot">
                                            <span v-if="item.content">{{ item.content }}</span>
                                            <span v-else>无会签内容</span>
                                            <attach-view :attachment="item.attachment"></attach-view>
                                        </div>
                                        <div class="times">
                                            <span>经办人：{{ item.staffName }}</span>
                                            <span>办理时间：{{ item.stopTime }}</span>
                                            <span v-if="!item.isRoot">耗时：{{ utils.getTimeStr(item.time) }}</span>
                                        </div>
                                    </div>
                                </a-timeline-item>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane style="padding-left:20px" key="process" tab="办理记录">
                    <a-timeline>
                        <a-timeline-item v-if="data.status != '已结束'">
                            <a-icon slot="dot" type="loading"></a-icon>
                            <div class="process">
                                <div class="title">{{ data.flow.name }}</div>
                                <div>
                                    <span>待处理</span>
                                </div>
                            </div>
                        </a-timeline-item>
                        <div ref="timeline" v-for="(item, index) in data.process" :key="index">
                            <a-timeline-item color="green">
                                <div class="process">
                                    <div class="title">{{ item.flowName }}</div>
                                    <div v-if="!item.isRoot">
                                        <span v-if="item.content">{{ item.content }}</span>
                                        <span v-else>无会签内容</span>
                                        <attach-view :attachment="item.attachment"></attach-view>
                                    </div>
                                    <div class="times">
                                        <span>经办人：{{ item.staffName }}</span>
                                        <span>办理时间：{{ item.stopTime }}</span>
                                        <span v-if="!item.isRoot">耗时：{{ utils.getTimeStr(item.time) }}</span>
                                    </div>
                                </div>
                            </a-timeline-item>

                        </div>
                    </a-timeline>
                </a-tab-pane>
            </a-tabs>
            <div style="margin-top: 20px;" v-if="activeKey == 'form'">
                <div style="margin-bottom: 10px;">
                    <a-checkbox-group v-model="arr" @change="changCheck" :default-value="['1', '3']">
                        <a-checkbox value="1">表单</a-checkbox>
                        <a-checkbox value="2">流程表</a-checkbox>
                        <a-checkbox value="3">附件</a-checkbox>
                    </a-checkbox-group>
                </div>
                <a-button @click="dayin">下载</a-button>
            </div>
            <div class="footer" v-if="data.isAuth">
                <div>
                    <a-form-model ref="flowForm" :model="flowData">
                        <a-form-model-item prop="content" label="我的意见" :required="data.flow.countersign == '强制会签'">
                            <a-textarea v-model="flowData.content" placeholder="输入会签意见"></a-textarea>
                        </a-form-model-item>
                        <a-form-model-item prop="attachment" v-if="data.isAttach">
                            <attach-upload v-model="flowData.attachment"></attach-upload>
                        </a-form-model-item>
                    </a-form-model>
                </div>

                <div class="button">
                    <a-button type="primary" @click="doNext()">转交下一步</a-button>
                    <a-button type="danger" @click="showBackList = true"
                        v-if="data.flow.back != '禁止回退' && processRecord.length > 0">退回</a-button>
                    <a-button @click="close()">取消关闭</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal v-model="showBackList" title="请选择回退步骤" :width="300" @ok="doBack()" centered destroyOnClose>
            <a-select style="width:100%" v-model="backFlowId" placeholder="请选择回退步骤">
                <a-select-option :value="item.value" v-for="(item, index) in processRecord" :key="index">
                    <span>{{ item.text }}</span>
                </a-select-option>
            </a-select>
        </a-modal>
        <a-modal v-model="exmineData" :title="data.nextFlowName" :width="300" @ok="doNext()" centered destroyOnClose>
            <div>
                <examine ref="exmines" @success="success" @focus="examines" :formData="Data.formData" :flowId="flowId">
                </examine>
            </div>
        </a-modal>
    </div>
</template>

<script>
import attachView from '@/components/common/attach-view.vue';
import attachUpload from '@/components/common/attach-upload.vue';
import formUtils from '@/common/formUtils';
import utils from '@/common/utils';
import request from '@/config/request';
import examine from './examine';
import JSZip from 'jszip';
import axios from 'axios';
export default {
    components: { attachUpload, attachView, examine },
    data() {
        return {
            visible: false,
            data: null,
            activeKey: 'form',
            formJson: {},
            formData: [],
            readList: [],
            hideList: [],
            flowData: {
                attachment: [],//附件
                content: ''//会签意见
            },
            flowId: '',
            backFlowId: '',
            showBackList: false,
            Data: {},
            exmineData: false,
            isTime: false,
            arr: ['1', '3'],
            htmlTitle: '',
            staffId: '',
            isDown: false
        };
    },
    mounted() {


    },
    computed: {
        processRecord() {
            const flowIds = [];
            const result = [];
            this.data.process.forEach(item => {
                //如果允许回退到之前的所有步骤，才可以回退到根节点
                if (item.isRoot) {
                    if ((this.data.flow.back == '允许回退之前步骤' || this.data.flow.parentId == 'root') && flowIds.indexOf(item.flowId) < 0) {
                        result.push({ text: item.flowName, value: item.flowId });
                        flowIds.push(item.flowId);
                    }
                } else {
                    if (item.flowId != this.data.flow.flowId && flowIds.indexOf(item.flowId) < 0) {
                        result.push({ text: item.flowName, value: item.flowId });
                        flowIds.push(item.flowId);
                    }
                }
            });
            return result;
        }
    },
    methods: {
        success(e) {
            this.staffId = e;
        },
        changCheck(e) {
            if (e.includes('2')) {
                this.isTime = true;
            } else {
                this.isTime = false;
            }

        },
        async dayin() {
            this.isDown = true;
            this.htmlTitle = this.data.title;
            const formTitle = this.$refs.kfb.$el.innerText.split('\n')[0];
            if (formTitle.includes('表')) {
                this.htmlTitle = formTitle;
            }
            utils.showSpin();
            // if (this.data.attachment.length == 0) {
            //     await this.getPdf(this.htmlTitle, true);
            //     utils.closeSpin();
            //     return;
            // }
            if (!this.arr.includes('3')) {
                await this.getPdf(this.htmlTitle, true);
                utils.closeSpin();
                return;
            }
            // console.log(this.$refs.timeline);
            // await this.getPdf(this.htmlTitle, true);
            const form = {};

            const blob = await this.getPdf('this.htmlTitle', false);
            const base64Part = blob.split(",")[1];
            // 将 Base64 解码为二进制数据
            const binaryData = atob(base64Part);

            // 创建 Uint8Array 来构造 Blob
            const uint8Array = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            // 创建 Blob 对象
            const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });
            form.data = pdfBlob;
            form.name = `${this.htmlTitle}.pdf`;

            let b = this.data.process.map(item => {
                return item.attachment;
            });
            let d = this.data.process.map((item, index) => {
                return { name: `${item.flowName}-${item.staffName}${item.sign.slice(item.sign.lastIndexOf('.'))}`, url: item.sign };
            });
            let c = [];
            b.forEach(item => {
                if (item.length > 0) {
                    item.forEach(item => {
                        c.push(item);
                    });
                }
            });
            const aaa = [...this.data.attachment, ...c, ...d.filter(item => item.url != '')];
            const promises = aaa.map(async (item) => {
                console.log(item);
                try {
                    const response = await axios({
                        method: 'get',
                        url: item.url,
                        responseType: 'blob'
                    });
                    return { data: response.data, name: item.name };
                } catch (error) {
                    console.error('Error fetching the file:', error);
                    throw error;
                }
            });
            Promise.all(promises)
                .then(async results => {
                    // results 是一个包含所有请求结果的数组
                    results.push(form);
                    // 在这里可以对 Blob 数据进行操作，例如下载或展示
                    const zip = new JSZip();
                    // const folder = zip.folder('myFiles');
                    results.forEach(item => {
                        zip.file(item.name, item.data);
                    });
                    const blob = await zip.generateAsync({ type: 'blob' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = this.data.title + '.zip';
                    link.click();
                })
                .catch(error => {
                    console.error('Error fetching one or more files:', error);
                });

        },

        examines() {
            this.$refs.kfb.getData().then(ret => {
                if (this.data.flow.countersign == '强制会签' && utils.isEmpty(this.flowData.content)) {
                    utils.error('请填写会签意见'); return;
                }
                const data = formUtils.getData(ret, this.readList, this.hideList);
                const formData = Object.assign({}, this.formData, data);
                this.Data = { applyId: this.data.applyId, formData: JSON.stringify(formData), attachment: this.flowData.attachment, content: this.flowData.content };
                console.log(this.Data);

            });
        },
        show(data) {
            // console.log(data);
            this.flowId = data.flowId;
            this.data = data;
            this.formJson = JSON.parse(data.formJson);
            // console.log(this.formJson);
            this.formData = JSON.parse(data.formData);
            // console.log(this.formData, "data");
            // console.log(this.formJson, "json");
            this.visible = true;
            this.arr = ['1', '3'];
            this.activeKey = 'form',
                this.isTime = false;
            this.$nextTick(() => {
                this.initForm();
            });
        },
        // 转交下一步
        doNext() {
            this.$refs.kfb.getData().then(ret => {
                if (this.data.flow.countersign == '强制会签' && utils.isEmpty(this.flowData.content)) {
                    utils.error('请填写会签意见'); return;
                }
                const data = formUtils.getData(ret, this.readList, this.hideList);
                // console.log(data); return;
                const formData = Object.assign({}, this.formData, data);
                const postData = { applyId: this.data.applyId, formData: JSON.stringify(formData), attachment: this.flowData.attachment, content: this.flowData.content, staffId: this.staffId };
                if (!this.exmineData) {
                    utils.showSpin();
                    request.post('/platform/apply/getStaffList', { flowId: this.flowId, formData: postData.formData }).then(ret => {
                        if (ret.data.flowName) {
                            this.data.nextFlowName = ret.data.flowName;
                        }
                        if (ret.data.list && ret.data.list.length > 0) {
                            if (ret.data.list.length == 1) {
                                this.exmineData = false;
                                postData.staffId = ret.data.list[0].staffId;
                                this.ApplyNext(postData);
                                return;
                            }

                            this.exmineData = true;
                        } else {
                            this.exmineData = false;
                            this.ApplyNext(postData);
                        }
                    });
                }
                if (this.exmineData) {
                    this.ApplyNext(postData);
                }

            });

        },
        // 下一步
        ApplyNext(postData) {
            utils.showSpin();
            request.post("/platform/apply/doNext", postData).then(ret => {
                this.close();
                utils.success('操作成功').then(() => {
                    this.$emit('success');
                });
                this.exmineData = false;
            });
        },
        // 退回
        doBack() {
            if (this.data.flow.back == '禁止回退') {
                utils.error('当前流程禁止回退'); return;
            }
            if (this.backFlowId == '') {
                utils.error('请选择回退节点'); return;
            }
            this.$refs.kfb.getData().then(ret => {
                const data = formUtils.getData(ret, this.readList, this.hideList);
                const formData = Object.assign({}, this.formData, data);
                const postData = { flowId: this.backFlowId, applyId: this.data.applyId, formData: JSON.stringify(formData), attachment: this.flowData.attachment, content: this.flowData.content };
                utils.showSpin();
                request.post("/platform/apply/doBack", postData).then(ret => {
                    this.showBackList = false;
                    this.close();
                    utils.success('操作成功').then(() => {
                        this.$emit('success');
                    });
                });
            });
        },
        // 初始化表单的内容
        initForm() {
            //检查只读字段和隐藏字段
            const { readList, hideList } = formUtils.getFieldsRuleList(this.data.flow.formItems);
            if (hideList.length > 0) {
                this.$refs.kfb.hide(hideList);
            }
            if (readList.length > 0) {
                this.$refs.kfb.disable(readList);
            }
            // console.log(hideList, readList)
            // console.log(this.formData)
            //去掉必填项
            this.formJson = formUtils.checkDisable(this.formJson, readList, hideList);
            this.readList = readList;
            this.hideList = hideList;

            //设置字段值
            this.$refs.kfb.setData(this.formData);
        },
        close() {
            this.visible = false;
            this.backFlowId = '';
            this.flowData.content = '';
            this.flowData.attachment = [];
        }
    },
    props: {
        //是否显示底部提交按钮
        footer: {
            type: Boolean,
            default: () => false
        }
    }
};
</script>

<style lang="less" scoped>
#pdfDom {
    // width: 70%;
    padding: 20px 30px; //打印页面四周留白

    /deep/ .ant-input-disabled,
    /deep/ .ant-radio-wrapper-checked,
    /deep/ .ant-select-disabled,
    /deep/ .ant-input-number-disabled,
    /deep/ .ant-checkbox-wrapper-checked {
        // background: #F00;
        color: #333;


        span {
            color: #333;
        }

        .ant-checkbox-inner::after {
            color: #333;
            background: #fff;
        }
    }

}

.downClass {}


.process {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 14px !important;
    }



    .times {
        font-size: 12px;
        color: #999;

        >span {
            margin-right: 10px;
        }
    }
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;

    >div,
    .button {
        width: 100%;

        >button {
            margin: 0 10px;
        }
    }

    .button {
        width: 100;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
</style>