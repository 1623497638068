import depname from './depname.vue';
export default {
	type: 'depname', // 表单类型
	label: '部门', // 标题文字
	icon: 'icon-gallery',
	component: depname,
	options: {
		defaultValue: '',
		width: '100%',
		readonly: false,
		disabled: false,
		showLabel: true,
		clearable: true,
		placeholder: '部门'
	},
	model: '',
	key: '',
	rules: [{ required: false, message: '请填写部门' }]
};
