import Vue from 'vue';
import VueRouter from 'vue-router';
import db from '@/common/localstorage';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import login from '@/views/login';
import admin from '@/views/admin';
import layout from '@/views/layout';
import main from '@/views/system/main';
import Indexrouter from './indexrouter';
NProgress.configure({ showSpinner: false }); // NProgress Configuration
Vue.use(VueRouter);
/**
 * 重写原型对象中的push方法
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};
//动态路由
let menuList = [];
//初始化路由
const CommonRoute = [
	// {path: '', redirect: '/login'},
	{ path: '/', redirect: '/index' },
	{
		path: '/login',
		name: '员工登录',
		component: login
	},
	{
		path: '/admin',
		name: '管理员登录',
		component: admin
	},
	{
		path: '/home',
		redirect: '/system/main'
	},
	Indexrouter
];

const router = new VueRouter({
	mode: 'history',
	routes: CommonRoute,
	scrollBehavior: () => ({ y: 0 }),
	isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
	beforeEach() {
		// console.log(111);
	}
});

router.onError(error => {
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	if (isChunkLoadFailed) {
		// 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
		location.reload();
		// const targetPath = $router.history.pending.fullPath;
		// $router.replace(targetPath);
	}
});

const paths = [];
function extractPaths(data) {
	if (data.path) {
		paths.push(data.path);
	}
	if (data.children) {
		data.children.forEach(child => extractPaths(child));
	}
}

extractPaths(Indexrouter);
// console.log(paths);

/**
 * 免登录白名单
 * @type {string[]}
 */
const whiteList = ['/login', '/admin', ...paths];

router.beforeEach((to, from, next) => {
	NProgress.start();
	let token = db.get('USER_TOKEN');
	let user = db.get('USER');
	let userRouter = db.get('ROLES');
	//白名单内
	if (whiteList.indexOf(to.path) >= 0) {
		next();
		return;
	}
	//没有登录的情况下
	if (!token.length || !user || !userRouter.length) {
		next('/login');
		return;
	}
	//判断路由是否存在
	if (router.options.isAddDynamicMenuRoutes == false) {
		router.addRoutes(hanleFor(userRouter));
		router.options.isAddDynamicMenuRoutes = true;
		next({ ...to, replace: true });
	} else {
		next();
	}
});

router.afterEach(() => {
	NProgress.done();
});

function hanleFor(list) {
	// console.log(list)
	for (let i = 0; i < list.length; i++) {
		if (list[i].children && list[i].children != null && list[i].children.length > 0) {
			if (list[i].isMenu == 1) {
				let res = createRoute(list[i]);
				menuList.push(res);
			}
			hanleFor(list[i].children);
		} else {
			if (list[i].isMenu == 1) {
				let res = createRoute(list[i]);
				menuList.push(res);
			}
		}
	}
	return menuList;
}

function createRoute(menu) {
	if (menu.authorize == null || menu.authorize == undefined || menu.authorize == '') {
		return;
	}
	// 获取页面按钮
	let buttons = [];
	if (menu.children && menu.children.length > 0 && menu.btnNum > 0) {
		menu.children.forEach(x => {
			if (x.isButton == 1 && x.isShow == true) {
				buttons.push(x);
			}
		});
	}
	//子页面
	let menus = [];
	if (menu.children && menu.children.length > 0 && menu.menuNum > 0) {
		menu.children.forEach(x => {
			if (x.isMenu == 1) {
				menus.push(x);
			}
		});
	}
	// let url = menu.authorize.replace(":","/");
	return {
		path: '/' + menu.url,
		meta: { name: menu.name, buttons: buttons, menus: menus },
		component: layout,
		children: [
			{
				path: '',
				meta: { id: menu.menuId, name: menu.name, url: menu.url, buttons: buttons, menus: menus },
				component: () => import('@/views/' + menu.url + '.vue')
			}
		]
	};
}

export default router;
