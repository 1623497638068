<template>
	<a-modal title="邮件详情" :width="800" :visible="visible" @ok="handleOk" @cancel="handleCancel">
		<div class="mail email-view">
			<a-page-header class="header" :title="`主题：${mail.title}`"></a-page-header>
			<a-descriptions class="content" size="small" :column="3">
				<a-descriptions-item label="发件人">
					<div class="value">{{ mail.sendName }}</div>
				</a-descriptions-item>
				<a-descriptions-item label="发送时间">
					<div class="value">{{ mail.createTime }}</div>
				</a-descriptions-item>
			</a-descriptions>
			<hr />
			<a-row class="content" :column="1">
				<a-col>
					<span style="font-size:18px">内容：</span>
					<div class="body" style="color:black;font-size:20px" v-html="mail.content"></div>
				</a-col>
				<br />
				<attachView v-if="mail.attachment" :attachment="mail.attachment"></attachView>
			</a-row>
		</div>
	</a-modal>
</template>

<script>
import attachView from '@/components/common/attach-view.vue';
import utils from '@/common/utils';

export default {
	components: { attachView },
	data() {
		return {
			visible: false,
			mail: {}
		};
	},

	methods: {
		show(mail) {
			console.log(mail);
			this.visible = true;
			utils.showSpin();
			this.http
				.get('/platform/mail/receive/read/' + mail.id)
				.then(ret => {
					if (ret.code == 200) {
						utils.closeSpin();
						this.mail = ret.data;
					}
				})
				.catch(err => {
					console.log(err);

					utils.closeSpin();
				});
		},
		handleOk() {
			this.visible = false;
		},
		handleCancel() {
			this.visible = false;
		}
	}
};
</script>

<style scoped>
.mail {
	border: 1px solid black;
}
.content {
	box-sizing: border-box;
	position: relative;
	padding: 16px 24px;
}
.email-view {
	padding: 24px;
}

.email-view .header {
	margin-bottom: 20px;
}

.email-view .header .title {
	font-size: 20px;
	font-weight: bold;
}

.email-view .header .actions {
	align-items: center;
}

.email-view .meta {
	margin-bottom: 20px;
}

.email-view .meta .label {
	font-weight: bold;
}

.email-view .subject {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
}

.email-view .body {
	margin-bottom: 20px;
}
</style>
