<template>
	<div class="containerq">
		<img class="img" :src="data.src" alt="" />
		<div class="content">
			<div class="title">
				<div @click="clicktitle">{{ data.title }}</div>
			</div>
			<div class="dac" v-html="filteredContent.substr(0, 150)"></div>
			<div class="con">
				<div>作者：{{ data.staffName }}</div>
				<div>{{ data.createTime }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			filteredContent: ''
		};
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		success: {
			type: Function
		}
	},
	mounted() {
		this.filterContent();
	},
	methods: {
		clicktitle() {
			this.$emit('success');
		},
		filterContent() {
			// 使用正则表达式过滤掉图片标签和空标签
			let filtered = this.data.content.replace(/<img[^>]*>/g, '');
			filtered = filtered.replace(/<[^\/>][^>]*><\/[^>]+>/g, '');
			this.filteredContent = filtered;
		}
	}
};
</script>

<style lang="less" scoped>
.containerq {
	width: 100%;
	height: 100%;
	margin-bottom: 20px;
	padding-bottom: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 2px solid #f7f7f7;
}
.content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	flex: 1;
	.title {
		cursor: pointer;
		display: flex;
		color: #e96432;
		margin-bottom: 5px;
		.status {
			margin-right: 5px;
			color: blue;
		}
	}
}
.dac {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	text-indent: 2em;
	font-size: 12px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	/* 显示2行 */
	line-clamp: 2;
	/* 显示2行 */
	white-space: pre-wrap;
}
.con {
	display: flex;
	justify-content: space-between;
}
.img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
</style>
