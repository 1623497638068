<template>
	<div>
		<a-upload :action="action" :headers="header" :file-list="fileList" list-type="picture" :before-upload="beforeUpload" :multiple="multiple" @change="upDone" :remove="removeItem" ref="lod" @preview="preview">
			<a-button :size="size">
				<a-icon type="upload" />
				上传附件
			</a-button>
		</a-upload>
		<office ref="office"></office>
	</div>
</template>
<script>
/**
 * 附件上传组件
 * example <attach-upload v-model="formData.attachment"></attach-upload>
 * @param multiple Boolean 是否允许多附件
 * @param limit Number 允许上传附件的数量 default 3
 */
import request, { UpLoadUrl, getHeader } from '@/config/request';
import utils from '@/common/utils';
import office from '@/components/office/document';
export default {
	components: { office },
	data() {
		return {
			action: UpLoadUrl(),
			header: getHeader(),
			fileList: [],
			Ele: []
		};
	},
	mounted() {},

	methods: {
		//文件上传之前
		beforeUpload(file) {
			utils.showSpin();
		},
		//完成
		upDone(info) {
			utils.closeSpin();
			let fileList = [...info.fileList];
			if (this.multiple && this.limit > 1) {
				fileList = fileList.slice(-this.limit);
			} else {
				fileList = fileList.splice(-1);
			}
			const result = [];
			fileList = fileList.map(file => {
				if (file.status == 'done') {
					if (file.response) {
						if (file.response.code == 200) {
							file.status = 'done';
							file.name = file.response.data.name;
							file.url = file.response.data.url;
						} else {
							file.status = 'error';
							file.response = file.response.message;
						}
					}
				} else if (file.status == 'error') {
					file.status = 'error';
					file.response = file.response;
				}
				if (file.status == 'done') {
					const item = { uid: file.uid, name: file.name, url: file.url, status: file.status };
					result.push(item);
				} else {
					const item = { uid: file.uid, name: file.name, response: file.response, status: 'error' };
					result.push(item);
				}
				return file;
			});
			this.fileList = fileList;

			const status = info.file.status;
			// console.log(status)
			if (status == 'done') {
				// console.log(result)

				this.$emit('input', result);
			}
		},
		// 删除文件
		removeItem(file) {
			this.fileList.map((item, index) => {
				if (item.uid == file.uid) {
					this.fileList.splice(index, 1);
				}
			});
			this.$emit('input', this.fileList);
			return true;
		},
		preview(file) {
			if (utils.isWordOrExcelFile(file.name)) {
				this.$refs.office.show(file.url, 1);
			} else if (utils.isImageFile(file.name)) {
				utils.openImage(file.url);
			} else {
				window.open(file.url, '_blank');
			}
		},
		//初始化父组件过来的文件列表
		initFileList(value) {
			// console.log(value);
			if (utils.isEmpty(value)) {
				this.fileList = [];
				return;
			}
			value.forEach((item, index) => {
				item.uid = item.uid ? item.uid : utils.getRandStr(8);
				item.response = item.response ? item.response : '';
				item.status = item.status ? item.status : 'done';
				if (utils.isEmpty(item.url)) {
					item.status = 'error';
					item.response = '文件未上传成功';
				}
			});
			this.fileList = JSON.parse(JSON.stringify(value));
		}
	},
	props: {
		value: {
			type: Object | Array | String,
			default: () => {
				return [];
			}
		},
		//是否允许上传多个附件
		multiple: {
			type: Boolean,
			default: () => {
				return true;
			}
		},
		//最大允许上传数量
		limit: {
			type: Number,
			default: () => {
				return 3;
			}
		},
		size: {
			type: String,
			default: () => 'small'
		}
	},
	watch: {
		value: {
			handler(value) {
				this.initFileList(value);
			},
			deep: false,
			immediate: false
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	}
};
</script>

<style></style>
