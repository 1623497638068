<template>
	<div ref="tree">
		<a-spin tip="Loading..." :spinning="loading">
			<a-tree-select v-model="depIds" :treeData="treeData" :treeDefaultExpandedKeys="getExpandedKeys()"
				style="width: 100%" placeholder="请选择部门" :multiple="multiple" :treeCheckStrictly="true"
				:labelInValue="false"
				:replaceFields="{ title: 'name', value: 'depId', children: 'children', key: 'depId' }"
				:tree-checkable="multiple" @change="changeData" showCheckedStrategy="SHOW_ALL" allowClear
				:getPopupContainer="() => $refs.tree" :disabled="disabled"></a-tree-select>
		</a-spin>

	</div>
</template>

<script>
/**
 * 部门选择组件
 * @example <select-depart v-model="formData.managerIds" :multiple="true"></select-depart>
 * @example <select-depart v-model="param.id" :multiple="false"></select-depart>
 */
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtils from '@/common/apiUtil';
export default {
	data() {
		return {
			loading: false,
			depIds: [],
			treeData: []
		};
	},
	mounted() {
		this.getTreeData();
	},
	methods: {
		changeData(value, label, extra) {
			// console.log(this.depIds);
			let result = this.depIds ? this.depIds : '';
			if (typeof result == 'object') {
				result = result.map(item => item.value);
			}
			this.$emit('input', result);
			setTimeout(() => {
				this.$emit('change', result);
			}, 300);
		},
		// 获取数据
		getTreeData() {
			this.loading = true;
			// utils.loading();
			request.get('/platform/depart/scope-list', { organId: this.organId, isAll: 1 }).then(ret => {
				// console.log(ret);
				this.treeData = ret.data;
			}).finally(() => {
				// this.loading = false;
			});
		},
		getExpandedKeys() {
			if (typeof this.depIds != 'object') {
				const id = parseInt(this.depIds);
				return [id];
			} else {
				return this.depIds.map(item => item.value);
			}
		}
	},
	props: {
		value: {
			type: Object | Array | String | Number,
			default: () => {
				return '';
			}
		},
		// 是否可多选
		multiple: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
		organId: {
			type: String | Number,
			default: () => {
				return apiUtils.getOrganId();
			}
		},
		disabled: {
			type: Boolean,
			default: () => false
		},
		//获取所有单位的数据
		isAll: {
			type: Boolean,
			default: () => false
		}
	},
	watch: {
		value: {
			handler(v) {
				this.loading = true;
				setTimeout(() => {
					if (utils.isEmpty(v)) {
						this.depIds = [];
					} else {

						if (typeof v == 'string' || typeof v == 'number') {
							if (typeof v == 'number') {
								this.depIds = v;
							} else {
								this.depIds = v.split(',');
							}
						} else {
							setTimeout(() => {
								const result = [];
								function flattenNodes(node) {
									result.push(node);
									if (node.children) {
										node.children.forEach(childNode => {
											flattenNodes(childNode, result);
										});
									}
								}
								this.treeData.forEach(node => {
									flattenNodes(node);
								});
								let c = [];
								v.forEach((itemv, index) => {
									let d = [];
									d = result.filter(item => item.depId == itemv);
									if (!c[index]) {
										c[index] = {};
									}
									c[index].value = d[0].depId;
									c[index].label = d[0].name;
								});
								this.depIds = c;
							}, 1000);
						}
					}
					this.loading = false;
				}, 100);
			},
			deep: false,
			immediate: true
		},
		organId(v) {
			if (utils.isEmpty(v)) {
				return;
			}
			// this.depIds = [];
			this.getTreeData();
		}
	}
};
</script>

<style></style>
