import ktitle from './title.vue';
export default {
	type: 'title', // 表单类型
	label: '表单标题', // 标题文字
	icon: 'icon-gallery',
	component: ktitle,
	options: {
		showLabel: false,
		field: false
	},
	model: '',
	key: '',
	rules: []
};
