<template>
    <div class="proof" :class="[orientation]">
        <span v-if="value">【{{ record.options.addonBefore }}{{ value }}】</span>
        <span v-else-if="record.options.defaultValue">【{{ record.options.addonBefore }}{{ record.options.defaultValue
        }}】</span>
        <span v-else>【{{ record.options.addonBefore }}示例文号】</span>
    </div>
</template>

<script>
export default {
    name: 'proof',
    props: ["record", "value"],
    computed: {
        orientation() {
            if (this.record.options.orientation) {
                return this.record.options.orientation;
            } else {
                return 'center';
            }
        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.proof {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.left {
    justify-content: flex-start;
}

.center {
    justify-content: center;
}

.right {
    justify-content: flex-end;
}
</style>