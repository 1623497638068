<template>
	<div id="vue-tinymce">
		<vue-tinymce v-model="content" :setting="setting" />
	</div>
</template>

<script>
import request, { getHeader, getUploadUrl, doUp } from '@/config/request'
import aes from '@/common/aesUtils.js'
import utils from '@/common/utils'
export default {
	name: 'vueTinymceEditor',
	data() {
		return {
			content: this.value,
			setting: {
				menubar: true,  // 菜单栏
				//inline: true,   //开启内联模式
				readonly: false, // 只读
				toolbar:
					"undo redo | fullscreen formatpainter  | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table emoticons | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | indent2em |superscript subscript | removeformat | wordcount code  ",
				toolbar_drawer: "sliding",
				quickbars_selection_toolbar: "removeformat formatpainter | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
				plugins: "fullscreen formatpainter link image media table lists quickbars wordcount emoticons code autoresize indent2em",
				language: 'zh-Hans',
				autoresize_min_height: 350, //编辑区域的最小高度
				height: 350,
				max_height:350,
				toolbar_sticky: true,//工具栏粘性定位
				// 图片上传地址(自动上传)
				// images_upload_url: baseUploadUrl + 'image/vueTinymce/',
				// 允许粘贴图片上传,及格式
				paste_data_images: true,
				images_file_types: 'jpeg,jpg,png,gif,bmp,webp',
				// 此处为图片上传处理函数 (手动上传)
				images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
					const xhr = new XMLHttpRequest();
					xhr.withCredentials = false;
					xhr.open('POST', getUploadUrl());
					let header = getHeader();
					xhr.setRequestHeader('sign', header.sign);
					xhr.setRequestHeader('Authorization', header.Authorization);
					xhr.setRequestHeader('Platform', header.Platform);
					xhr.upload.onprogress = (e) => {
						progress(e.loaded / e.total * 100);
					};

					xhr.onload = () => {
						if (xhr.status === 403) {
							reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
							return;
						}

						if (xhr.status < 200 || xhr.status >= 300) {
							reject('HTTP Error: ' + xhr.status);
							return;
						}

						const json = JSON.parse(xhr.responseText);
						if (!json || typeof json.data != 'string') {
							reject('Invalid JSON: ' + xhr.responseText);
							return;
						}
						resolve(json.data);
					};

					xhr.onerror = () => {
						reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
					};

					const formData = new FormData();
					formData.append('file', blobInfo.blob(), blobInfo.filename());

					xhr.send(formData);
				}),
				//上传视频
				file_picker_callback: (callback, value, meta) => {
					let input = document.createElement('input');//创建一个隐藏的input
					input.setAttribute('type', 'file');
					if (meta.filetype === 'file') {
						input.setAttribute("accept", ".doc,.docx,.xls,.xlsx,.pdf");
					}
					if (meta.filetype === 'image') {
						input.setAttribute("accept", ".jpg,.png,.jpeg,.gif,.bmp");
					}
					if (meta.filetype === 'media') {
						input.setAttribute("accept", ".mp4,.flv");
					}
					input.onchange = function () {
						let file = this.files[0];
						doUp(file).then(json => {
							const url = json.data;
							callback(url, { alt: file.name, text: file.name });
						})
					}
					//触发点击
					input.click();
				},
			}
		}
	},
	model: {
		prop: "value",
		event: 'input'
	},
	props: {
		// 接收值父组件传递值
		value: {
			type: String,
			default: () => {
				return ''
			}
		}
	},
	watch: {
		value(newValue) {
			this.content = newValue;
		},
		content(newValue) {
			this.$emit("input", newValue);
		}
	}
}
</script>

<style>
/* 富文本按钮功能优先级,最好 > 2500  弹出默认2000+ */
.tox-tinymce-aux {
	z-index: 9999 !important;
}
</style>
