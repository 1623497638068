<template>
	<div id="mains">
		<a-row :gutter="15" style="padding: 10px 0">
			<a-col :span="8">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">通知公告</p>
					<!-- <a @click="doUrl('/synthesize/notice/index')">更多</a> -->
					<div class="card-body">
						<a-list size="small" :data-source="noticeList">
							<a-list-item slot="renderItem" slot-scope="item" :locale="{ emptyText: '暂无数据' }"
								@click="$refs.noticeDetail.show(item.noticeId)">
								<div class="row-item">
									<div class="row-title">
										<span>【{{ item.categoryName }}】</span>
										<a-tooltip :title="item.title">
											<a>{{ item.title }}</a>
										</a-tooltip>
									</div>
									<span>{{ item.createTime }}</span>
								</div>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col>
			<a-col :span="8">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">新闻动态</p>
					<div class="card-body">
						<a-list size="small" :data-source="newsList">
							<a-list-item slot="renderItem" slot-scope="item" :locale="{ emptyText: '暂无数据' }"
								@click="$refs.newsDetail.show(item.newsId)">
								<div class="row-item">
									<a-tooltip :title="item.title">
										<a>{{ item.title }}</a>
									</a-tooltip>
									<span>{{ item.createTime }}</span>
								</div>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col>
			<a-col :span="8">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">工作流</p>
					<div class="card-body">
						<a-list size="small" :data-source="applyList">
							<a-list-item slot="renderItem" slot-scope="item" :locale="{ emptyText: '暂无数据' }">
								<div class="row-item">
									<div class="row-title" @click="getApplyDetail(item)">
										<span>【{{ item.approveName }}】</span>
										<a-tooltip :title="item.title"><a >{{ item.title
										}}</a></a-tooltip>
									</div>
									<div>
										<a-icon type="clock-circle" style="color:rgb(0, 119, 255);;margin-right:10px"
											v-if="item.status == '处理中'"></a-icon>
										<a-icon type="check-circle" style="color:green;margin-right:10px" v-else></a-icon>
										<span>{{ item.status }}</span>
									</div>
								</div>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col>
		</a-row>

		<a-row :gutter="15">
			<a-col :span="8">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">最新邮件</p>
					<div class="card-body">
						<a-list size="small" :data-source="mailList">
							<a-list-item slot="renderItem" slot-scope="item" :locale="{ emptyText: '暂无数据' }">
								<div class="row-item">
									<div class="row-title">
										<span>{{ item.sendName }}：</span>
										<a-tooltip :title="item.title">
											<a @click="$refs.mail.show(item)">{{ item.title }}</a>
										</a-tooltip>
									</div>
									<span>{{ item.createTime }}</span>
								</div>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col>
			<a-col :span="8">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">近期计划</p>
					<div class="card-body">
						<a-list size="small" :data-source="planList">
							<a-list-item slot="renderItem" slot-scope="item" :locale="{ emptyText: '暂无数据' }">
								<div class="row-item">
									<div class="row-title">
										<span>【{{ item.categoryName }}】</span>
										<a-tooltip :title="item.title">
											<a @click="$refs.planDetail.show(item)">{{ item.title }}</a>
										</a-tooltip>
									</div>
									<span>{{ item.staffName }}</span>
								</div>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col>
			<a-col :span="8">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">工作汇报</p>
					<div class="card-body">
						<a-list size="small" :data-source="reportList">
							<a-list-item slot="renderItem" slot-scope="item">
								<div class="row-item">
									<div class="row-title">
										<span>{{ item.staffName }}：</span>
										<a-tooltip :title="item.title">
											<a @click="$refs.reportDetail.show(item)">{{ item.title }}</a>
										</a-tooltip>
									</div>
									<span>{{ item.createTime }}</span>
								</div>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col>
			<!-- <a-col :span="6">
				<a-card :bordered="false" :dis-hover="true">
					<p slot="title">我的消息</p>
					<div class="card-body">
						<a-list size="small" :data-source="messageList">
							<a-list-item slot="renderItem" slot-scope="item">
								<a-list-item-meta>
									<div slot="title">
										<span>【{{ item.type }}】</span>
										<span>{{ item.createTime }}</span>
									</div>
									<div class="row-description" slot="description">
										<a>{{ item.content }}</a>
									</div>
								</a-list-item-meta>
							</a-list-item>
						</a-list>
					</div>
				</a-card>
			</a-col> -->
		</a-row>

		<notice-detail ref="noticeDetail" />
		<news-detail ref="newsDetail" />
		<mail-deatil ref="mail"></mail-deatil>
		<plan-detail ref="planDetail"></plan-detail>
		<reportDetail ref="reportDetail"></reportDetail>
		<apply-detail ref="applyDetail"></apply-detail>

	</div>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
import noticeDetail from '../synthesize/notice/components/view-detail.vue';
import newsDetail from '../synthesize/news/components/view-detail.vue';
import mailDeatil from '../profile/mail/components/mailDetail.vue';
import planDetail from '../profile/plan/components/detail.vue';
import reportDetail from '../profile/report/components/detail.vue';
import applyDetail from '../flow/work/components/apply-detail.vue';
export default {
	components: { noticeDetail, newsDetail, mailDeatil, planDetail, reportDetail, applyDetail },
	data() {
		return {
			noticeList: [], //通知公告
			newsList: [], //新闻
			planList: [], //工作计划
			mailList: [], //邮件
			reportList: [], //工作汇报
			messageList: [], //我的消息
			applyList: [] //工作流
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		// 获取数据
		getData() {
			utils.showSpin();
			request.get('/platform/info/workbench', {}).then(ret => {
				// console.log(ret);
				this.noticeList = ret.data.noticeList;
				this.newsList = ret.data.newsList;
				this.planList = ret.data.planList;
				this.reportList = ret.data.reportList;
				this.mailList = ret.data.mailList;
				this.messageList = ret.data.messageList;
				this.applyList = ret.data.applyList;
			});
		},
		// 获取审批流程详情
		getApplyDetail(apply) {
			utils.showSpin();
			request.get("/platform/apply/detail/" + apply.applyId, {}).then(ret => {
				// console.log(ret.data.isAuth);
				this.$refs.applyDetail.show(ret.data);
			})
		}
	}
};
</script>

<style lang="less" scoped>
.row-item {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.row-title {
		width: calc(100% - 160px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	>a {
		width: calc(100% - 160px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	>span {
		width: 160px;
		text-align: right;
	}
}
</style>
