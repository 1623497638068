/**
 * AES加密解密工具类
 */
import CryptoJS from 'crypto-js';
import { aesKey } from './aesKey';
import { commonUtils } from './common-utils';
export default {
	/**
	 * 对象指定属性加密
	 * @param {Object} 原始数据对象 jsonData
	 * @param {String|Array} 需要加密的字段 field
	 * @returns
	 */
	getJsonData(jsonData, field, isIv) {
		if (typeof jsonData != 'object') {
			return jsonData;
		}
		if (field == null || field == undefined) {
			return jsonData;
		}
		if (isIv) {
			jsonData.iv = commonUtils.getRandStr(16);
		} else {
			jsonData.iv = null;
		}
		if (typeof field == 'string') {
			if (jsonData[field] != undefined) {
				jsonData[field] = this.encrypt(jsonData[field], jsonData.iv);
			} else {
				return jsonData;
			}
		} else {
			for (var i = 0; i < field.length; i++) {
				let k = field[i];
				jsonData[k] = this.encrypt(jsonData[k], jsonData.iv);
			}
		}
		if (!isIv) {
			delete jsonData.iv;
		}
		return jsonData;
	},

	/**
	 * 加密
	 * @param {*} word
	 * @param {*} iv
	 * @returns
	 */
	encrypt(word, iv) {
		if (typeof word != 'string') {
			word = JSON.stringify(word);
		}
		let encrypts = '';
		let key = CryptoJS.enc.Utf8.parse(aesKey);
		word = CryptoJS.enc.Utf8.parse(word);
		if (!commonUtils.isEmpty(iv)) {
			iv = CryptoJS.enc.Utf8.parse(iv);
			var encrypted = CryptoJS.AES.encrypt(word, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
			encrypts = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
		} else {
			var encrypted = CryptoJS.AES.encrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
			encrypts = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
		}
		return encodeURIComponent(encrypts);
	},

	/**
	 * 解密
	 * @param {*} word
	 * @param {*} iv
	 * @returns
	 */
	decrypt(word, iv) {
		if (word == undefined || word == null) {
			return;
		}
		const str = decodeURIComponent(word);
		var key = CryptoJS.enc.Utf8.parse(aesKey);
		let base64 = CryptoJS.enc.Base64.parse(str);
		let src = CryptoJS.enc.Base64.stringify(base64);
		try {
			if (iv) {
				iv = CryptoJS.enc.Utf8.parse(iv);
				var decrypt = CryptoJS.AES.decrypt(src, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
				let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
				return decryptedStr.toString();
			} else {
				var decrypt = CryptoJS.AES.decrypt(src, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
				let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
				return decryptedStr.toString();
			}
		}catch (e) {

		}
	}
};
