<template>
	<div class="login">
		<a-col :xs="{ span: 12, offset: 6 }" :md="{ span: 6, offset: 15 }">
			<a-card title="管理员登录">
				<a-form-model ref="loginFrom" :model="formData" :rules="loginRule">
					<a-form-model-item prop="username">
						<a-input type="link" v-model="formData.username" placeholder="请输入账户名称" :maxLength="20"
							@pressEnter="handleSubmit('loginFrom')">
							<a-icon type="user" slot="prefix" />
						</a-input>
					</a-form-model-item>
					<a-form-model-item prop="password">
						<a-input type="password" v-model="formData.password" placeholder="请输入登录密码" :maxLength="30"
							@pressEnter="handleSubmit('loginFrom')">
							<a-icon type="lock" slot="prefix"></a-icon>
						</a-input>
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" block long @click="handleSubmit('loginFrom')"
							:loading="loading">立即登录</a-button>
						<a-button type="default" block long @click="doUrl('/login')">返回员工登录</a-button>
					</a-form-model-item>
				</a-form-model>
			</a-card>
		</a-col>
		<Verify @success="captchaSuccess" mode="pop" captchaType="blockPuzzle" ref="verify"></Verify>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import Verify from '@/components/verifition/Verify';
import db from '@/common/localstorage';
import request from '@/config/request';
export default {
	components: { Verify },
	name: 'login',
	data() {
		return {
			appid: '',
			loading: false,
			verifyCode: null,
			formData: {
				username: '',
				password: '',
				token: '',
				driver: 'web'
			},
			loginRule: {
				username: [
					{ required: true, message: '请输入登录账号' },
					{ min: 3, max: 11, message: '请输入有效的账户信息' }
				],
				password: [
					{ required: true, message: '请输入登录密码' },
					{ min: 3, message: '请输入有效的登录密码' }
				]
			}
		};
	},
	created() {
		this.db.clear();
		this.$router.options.routes = [];
	},
	mounted() {
		this.getOrganList();
	},
	methods: {
		captchaSuccess(data) {
			this.formData.token = data.captchaVerification;
			this.doSubmit();
		},
		// 用户名密码登录
		handleSubmit(name) {
			this.$refs[name].validate(res => {
				if (res) {
					this.$refs.verify.show();
				}
			});
		},
		// 获取单位列表
		getOrganList() {
			request.get("/platform/login/organ").then(ret => {
				db.save("organ-list", ret.data);
			})
		},
		doSubmit() {
			this.loading = true;
			this.utils.loading();
			this.http
				.post('/platform/login', this.formData)
				.then(ret => {
					// this.loading = false;
					if (ret.code == 200) {
						this.saveLoginData(ret.data);
						return;
					}
					this.utils.error(ret.message);
				})
				.catch(err => {
					this.loading = false;
				});
		},
		...mapMutations({
			setToken: 'account/setToken',
			setPermissions: 'account/setPermissions',
			setRoles: 'account/setRoles',
			setUser: 'account/setUser'
		}),
		saveLoginData(data) {
			this.setToken(data.token);
			this.setUser(data);
			this.setPermissions(data.role);
			this.setRoles(data.menu);
			//登录成功
			this.utils.success('登录成功,正在进入系统....', 2).then(() => {
				window.location.href = '/system/main';
			});
		}
	}
};
</script>

<style scoped lang="less">
.login {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: url('../static/login-bg.jpg') no-repeat center center;
	background-size: cover;
	padding-top: 160px;
}
</style>
