<template>
	<div class="header">
		<a-row :gutter="15" type="flex" justify="center" align="middle">
			<a-col span="8" :offset="1"><img width="75%" src="@/static/homew.png" /></a-col>
			<a-col span="12" :offset="1">
				<div class="nav">
					<div @click="toRouter('/index/home')">网站首页</div>
					<div>
						<a-dropdown :getPopupContainer="e => this.$refs.col1" placement="bottomCenter">
							<div @click="toRouter('/index/introduce')">公司介绍</div>
							<a-menu slot="overlay">
								<a-menu-item v-for="(item, index) in introducelist" :key="index" @click="click('/index/introduce', item)">
									<div>{{ item }}</div>
								</a-menu-item>
							</a-menu>
						</a-dropdown>
					</div>

					<div @click="toRouter('/index/culture')">企业文化</div>

					<div @click="toRouter('/index/news')">新闻信息</div>

					<div @click="toRouter('/index/notice')">通知公告</div>

					<div @click="toRouter('/index/attract')">招商信息</div>

					<div @click="toRouter('/index/furnishings')">家居服务</div>

					<div @click="toRouter('/index/resources')">人力资源</div>

					<div @click="toRouter('/index/party')">党建工作</div>
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			introducelist: ['公司简介', '组织机构', '发展历程', '企业荣誉']
			// culturelist: ['价值理念', '愿景使命', '经营理念'],
			// newslist: ['公司公告', '企业动态', '行业与政策'],
			// attractlist: ['招聘信息'],
			// furnishingslist: ['商场指南', '品牌关注', '服务申请', '家居学堂', '联系我们', '虚拟漫游', '公交指南'],
			// resourceslist: ['队伍建设', '人才招聘', '培训动态'],
			// partylist: ['党建工作', '工会工作']
		};
	},
	methods: {
		// 点击跳转
		toRouter(path) {
			// console.log(path);
			this.$router.push({ path });
		},
		click(path, item) {
			// console.log(item);
			this.$router.push({ path, query: { name: item } });
		}
	}
};
</script>

<style scoped>
.header {
	width: 100%;
	height: 80px;
	min-width: 650px;
	color: #fff;
	background: #e96432;
	display: flex;
	overflow: hidden;
	justify-content: center;
}
.nav {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.ant-col {
	cursor: pointer;
	text-align: center;
}

.ant-dropdown-menu {
	background: #e96432 !important;
	color: #fff;
}
.ant-dropdown-menu-item {
	color: #fff !important;
	text-align: center;
}
</style>
