import duties from './duties.vue';
export default {
	type: 'duties', // 表单类型
	label: '职务', // 标题文字
	icon: 'icon-gallery',
	component: duties,
	options: {
		defaultValue: '',
		width: '100%',
		readonly: false,
		disabled: false,
		showLabel: true,
		clearable: true,
		placeholder: '职务'
	},
	model: '',
	key: '',
	rules: [{ required: false, message: '请选择职务' }]
};
