import aesUtils from "./aesUtils";
let db = {
  save(key, value) {
    localStorage.setItem(key, aesUtils.encrypt(value));
  },
  get(key) {
    const str = localStorage.getItem(key);
    if (str == undefined || str == null) {
      return '';
    }
    const data = aesUtils.decrypt(str);
    try {
      return JSON.parse(data || '');
    } catch {
      return data;
    }
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  }
}

export default db
