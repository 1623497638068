import { message, Spin, Modal } from 'ant-design-vue';
import * as websocket from '@/config/websocket.js';
import { commonUtils } from './common-utils';

let utils = {
	websocket,
	getMimeType(fileUrl) {
		let suffix = fileUrl.substring(fileUrl.lastIndexOf('.'));
		let type = '';
		if (suffix == '.xls') {
			type = 'application/vnd.ms-excel';
		} else if (suffix == '.xlsx') {
			type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		} else if (suffix == '.doc') {
			type = 'application/msword';
		} else if (suffix == '.docx') {
			type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		} else if (suffix == '.pdf') {
			type = 'application/pdf';
		} else if (suffix == '.ppt') {
			type = 'application/vnd.ms-powerpoint';
		} else if (suffix == '.png') {
			type = 'image/png';
		} else if (suffix == '.pptx') {
			type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		} else if (suffix == '.jpeg') {
			type = 'image/jpeg';
		} else if (suffix == '.zip') {
			type = 'application/zip';
		} else if (suffix == '.7z') {
			type = 'application/x-7z-compressed';
		} else if (suffix == 'tar') {
			type = 'application/x-tar';
		} else if (suffix == '.7z') {
			type = 'application/x-7z-compressed';
		}
		return type;
	},
	// 判断一个文件是否可以编辑
	isWordOrExcelFile(filename) {
		return filename.toLowerCase().match(/\.doc$/) || filename.toLowerCase().match(/\.docx$/) || filename.toLowerCase().match(/\.xls$/) || filename.toLowerCase().match(/\.xlsx$/);
	},
	// 判断一个文件是否为图片
	isImageFile(filename) {
		var imgExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
		// 防止文件名中有多个.
		function getFileExtension(filename) {
			var parts = filename.split('.');
			if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
				return '';
			}
			return parts.pop();
		}
		var fileExtension = getFileExtension(filename);

		for (var i = 0; i < imgExtensions.length; i++) {
			if (fileExtension.toLowerCase() === imgExtensions[i]) {
				return true;
			}
		}

		return false;
	},

	/**
	 * 空判断
	 * @param {Object} obj
	 */
	isEmpty(obj) {
		return commonUtils.isEmpty(obj);
	},
	/**
	 * 将秒转换成 x天x小时x分x秒 格式
	 * @param {Number} seconds
	 * @returns
	 */
	getTimeStr(seconds) {
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const days = Math.floor(seconds / oneDay);
		seconds -= days * oneDay;
		const hours = Math.floor(seconds / oneHour);
		seconds -= hours * oneHour;
		const minutes = Math.floor(seconds / oneMinute);
		seconds -= minutes * oneMinute;
		const parts = [];
		if (days > 0) {
			parts.push(`${days}天${days > 1 ? '' : ''}`);
		}
		if (hours > 0) {
			parts.push(`${hours}小时${hours > 1 ? '' : ''}`);
		}
		if (minutes > 0) {
			parts.push(`${minutes}分钟${minutes > 1 ? '' : ''}`);
		}
		if (seconds > 0) {
			parts.push(`${seconds}秒${seconds > 1 ? '' : ''}`);
		}
		return parts.length > 0 ? parts.join('') : '0秒';
	},
	// 预览图片
	openImage(img) {
		app.$emit('open-image', img);
	},
	//过滤表单提交中不必要的参数
	formatSubmitForm(form, submit) {
		let newForm = {};
		for (let key in form) {
			newForm[key] = submit[key];
		}
		return newForm;
	},
	//字符串转数字
	stringToInt(val) {
		let value = parseInt(val);
		return isNaN(val) ? '' : value;
	},
	//判断后缀名
	getSuffix(name) {
		let dotPos = name.lastIndexOf('.');
		if (dotPos < 0) {
			return null;
		}
		return name.substring(dotPos + 1).toLowerCase();
	},
	//加载框
	loading(time, callback) {
		message.loading({
			content: '加载中,请稍后....',
			duration: time ? time : 0,
			onClose: callback ? callback : null
		});
	},
	//确认框
	confirm(message) {
		return new Promise((resolve, reject) => {
			Modal.confirm({
				title: '操作提示',
				centered: true,
				content: message,
				closable: true,
				onOk: () => {
					resolve(true);
				}
			});
		});
	},
	//消息提示
	info(msg) {
		message.info(msg);
	},
	//警告
	warning(msg) {
		message.warning(msg);
	},
	//错误提示
	error(msg, duration) {
		return new Promise((resolve, reject) => {
			message.error({
				content: msg,
				duration: duration ? duration : 2,
				onClose: () => {
					resolve();
				}
			});
		});
	},
	//成功提示
	success(msg, duration) {
		return new Promise((resolve, reject) => {
			message.success({
				content: msg,
				duration: duration ? duration : 1,
				onClose: res => {
					resolve(res);
				}
			});
		});
	},
	//关闭消息提示和加载框
	closeAll() {
		message.destroy();
	},
	//开启全屏加载
	showSpin() {
		app.$emit('show-loading', 0);
	},
	//关闭全屏加载效果
	closeSpin() {
		app.$emit('hide-loading');
	},

	/**
	 * 生成随机字符串
	 * @param len
	 * @returns {string}
	 */
	getRandStr(len) {
		return commonUtils.getRandStr(len);
	},
	/**
	 * Url转对象
	 * @param url
	 * @returns {Object}
	 */
	urlToarray(url) {
		var strlen = url.indexOf('?');
		if (strlen != -1) {
			var urlstr = url.substr(strlen + 1);
		} else {
			var urlstr = url.substr(1);
		}
		//拆分key value
		var arr = urlstr.split('&');
		var obj = new Object();
		for (var i = 0; i < arr.length; i++) {
			var item = arr[i].split('=');
			obj[item[0]] = item[1];
		}
		return obj;
	},
	/**
	 * json对象转url
	 * @param param
	 * @param key
	 * @param encode
	 * @param n
	 * @returns {string}
	 */
	parseParam(param, key, encode, n = 0) {
		if (param == null) return '';
		var paramStr = '';
		var t = typeof param;
		if (t == 'string' || t == 'number' || t == 'boolean') {
			paramStr += '&' + key + '=' + param;
			// paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURI(encodeURI(param)) : param);
		} else {
			for (var i in param) {
				var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
				paramStr += parseParam(param[i], k, encode, i);
			}
		}
		if (n == 0) {
			return paramStr.substr(1);
		} else {
			return paramStr;
		}
	},
	/**
	 * 日期格式化
	 * @param time
	 * @param fmt
	 * @returns {*}
	 */
	formatDate(time, fmt) {
		var o = {
			'M+': time.getMonth() + 1, //月份
			'D+': time.getDate(), //日
			'h+': time.getHours(), //小时
			'm+': time.getMinutes(), //分
			's+': time.getSeconds(), //秒
			'q+': Math.floor((time.getMonth() + 3) / 3), //季度
			S: time.getMilliseconds() //毫秒
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
		for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
		return fmt;
	},
	/**
	 * 获取当前星期
	 * @param nowdate
	 * @returns {*}
	 */
	getWeek(nowdate) {
		var date = nowdate ? nowdate : new Date();
		var weekday = ['周 日', '周 一', '周 二', '周 三', '周 四', '周 五', '周 六'];
		return weekday[date.getDay()];
	},
	/**
	 * 手机号效验
	 * @param mobile
	 * @returns {boolean}
	 */
	checkMobile(mobile) {
		if (mobile == '' || mobile == undefined) {
			return false;
		}
		if (mobile.length != 11) {
			return false;
		}
		if (!/^((13[0-9])|(14[5,7,9])|(15([0-3]|[5-9]))|(166)|(17[0,1,3,5,6,7,8])|(18[0-9])|(19[8|9]))\d{8}$/.test(mobile)) {
			return false;
		} else {
			return true;
		}
	},
	/**
	 * 身份证号码验证
	 * @param card
	 * @returns {boolean}
	 */
	isCardNo(card) {
		// 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
		if (card == '' || card == undefined) {
			return false;
		}
		if (card.length > 18) {
			return false;
		}
		if (!/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(card)) {
			return false;
		} else {
			return true;
		}
	},
	/**
	 * MD5加密
	 * @param string
	 * @returns {string}
	 */
	md5(string) {
		var rotateLeft = function (lValue, iShiftBits) {
			return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
		};
		var addUnsigned = function (lX, lY) {
			var lX4, lY4, lX8, lY8, lResult;
			lX8 = lX & 0x80000000;
			lY8 = lY & 0x80000000;
			lX4 = lX & 0x40000000;
			lY4 = lY & 0x40000000;
			lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
			if (lX4 & lY4) return lResult ^ 0x80000000 ^ lX8 ^ lY8;
			if (lX4 | lY4) {
				if (lResult & 0x40000000) return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
				else return lResult ^ 0x40000000 ^ lX8 ^ lY8;
			} else {
				return lResult ^ lX8 ^ lY8;
			}
		};
		var F = function (x, y, z) {
			return (x & y) | (~x & z);
		};
		var G = function (x, y, z) {
			return (x & z) | (y & ~z);
		};
		var H = function (x, y, z) {
			return x ^ y ^ z;
		};
		var I = function (x, y, z) {
			return y ^ (x | ~z);
		};
		var FF = function (a, b, c, d, x, s, ac) {
			a = addUnsigned(a, addUnsigned(addUnsigned(F(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};
		var GG = function (a, b, c, d, x, s, ac) {
			a = addUnsigned(a, addUnsigned(addUnsigned(G(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};
		var HH = function (a, b, c, d, x, s, ac) {
			a = addUnsigned(a, addUnsigned(addUnsigned(H(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};
		var II = function (a, b, c, d, x, s, ac) {
			a = addUnsigned(a, addUnsigned(addUnsigned(I(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};
		var convertToWordArray = function (string) {
			var lWordCount;
			var lMessageLength = string.length;
			var lNumberOfWordsTempOne = lMessageLength + 8;
			var lNumberOfWordsTempTwo = (lNumberOfWordsTempOne - (lNumberOfWordsTempOne % 64)) / 64;
			var lNumberOfWords = (lNumberOfWordsTempTwo + 1) * 16;
			var lWordArray = Array(lNumberOfWords - 1);
			var lBytePosition = 0;
			var lByteCount = 0;
			while (lByteCount < lMessageLength) {
				lWordCount = (lByteCount - (lByteCount % 4)) / 4;
				lBytePosition = (lByteCount % 4) * 8;
				lWordArray[lWordCount] = lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition);
				lByteCount++;
			}
			lWordCount = (lByteCount - (lByteCount % 4)) / 4;
			lBytePosition = (lByteCount % 4) * 8;
			lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
			lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
			lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
			return lWordArray;
		};
		var wordToHex = function (lValue) {
			var WordToHexValue = '',
				WordToHexValueTemp = '',
				lByte,
				lCount;
			for (lCount = 0; lCount <= 3; lCount++) {
				lByte = (lValue >>> (lCount * 8)) & 255;
				WordToHexValueTemp = '0' + lByte.toString(16);
				WordToHexValue = WordToHexValue + WordToHexValueTemp.substr(WordToHexValueTemp.length - 2, 2);
			}
			return WordToHexValue;
		};
		var uTF8Encode = function (string) {
			string = string.replace(/\x0d\x0a/g, '\x0a');
			var output = '';
			for (var n = 0; n < string.length; n++) {
				var c = string.charCodeAt(n);
				if (c < 128) {
					output += String.fromCharCode(c);
				} else if (c > 127 && c < 2048) {
					output += String.fromCharCode((c >> 6) | 192);
					output += String.fromCharCode((c & 63) | 128);
				} else {
					output += String.fromCharCode((c >> 12) | 224);
					output += String.fromCharCode(((c >> 6) & 63) | 128);
					output += String.fromCharCode((c & 63) | 128);
				}
			}
			return output;
		};

		var x = Array();
		var k, AA, BB, CC, DD, a, b, c, d;
		var S11 = 7,
			S12 = 12,
			S13 = 17,
			S14 = 22;
		var S21 = 5,
			S22 = 9,
			S23 = 14,
			S24 = 20;
		var S31 = 4,
			S32 = 11,
			S33 = 16,
			S34 = 23;
		var S41 = 6,
			S42 = 10,
			S43 = 15,
			S44 = 21;
		string = uTF8Encode(string);
		x = convertToWordArray(string);
		a = 0x67452301;
		b = 0xefcdab89;
		c = 0x98badcfe;
		d = 0x10325476;
		for (k = 0; k < x.length; k += 16) {
			AA = a;
			BB = b;
			CC = c;
			DD = d;
			a = FF(a, b, c, d, x[k + 0], S11, 0xd76aa478);
			d = FF(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
			c = FF(c, d, a, b, x[k + 2], S13, 0x242070db);
			b = FF(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
			a = FF(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
			d = FF(d, a, b, c, x[k + 5], S12, 0x4787c62a);
			c = FF(c, d, a, b, x[k + 6], S13, 0xa8304613);
			b = FF(b, c, d, a, x[k + 7], S14, 0xfd469501);
			a = FF(a, b, c, d, x[k + 8], S11, 0x698098d8);
			d = FF(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
			c = FF(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
			b = FF(b, c, d, a, x[k + 11], S14, 0x895cd7be);
			a = FF(a, b, c, d, x[k + 12], S11, 0x6b901122);
			d = FF(d, a, b, c, x[k + 13], S12, 0xfd987193);
			c = FF(c, d, a, b, x[k + 14], S13, 0xa679438e);
			b = FF(b, c, d, a, x[k + 15], S14, 0x49b40821);
			a = GG(a, b, c, d, x[k + 1], S21, 0xf61e2562);
			d = GG(d, a, b, c, x[k + 6], S22, 0xc040b340);
			c = GG(c, d, a, b, x[k + 11], S23, 0x265e5a51);
			b = GG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
			a = GG(a, b, c, d, x[k + 5], S21, 0xd62f105d);
			d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
			c = GG(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
			b = GG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
			a = GG(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
			d = GG(d, a, b, c, x[k + 14], S22, 0xc33707d6);
			c = GG(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
			b = GG(b, c, d, a, x[k + 8], S24, 0x455a14ed);
			a = GG(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
			d = GG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
			c = GG(c, d, a, b, x[k + 7], S23, 0x676f02d9);
			b = GG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
			a = HH(a, b, c, d, x[k + 5], S31, 0xfffa3942);
			d = HH(d, a, b, c, x[k + 8], S32, 0x8771f681);
			c = HH(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
			b = HH(b, c, d, a, x[k + 14], S34, 0xfde5380c);
			a = HH(a, b, c, d, x[k + 1], S31, 0xa4beea44);
			d = HH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
			c = HH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
			b = HH(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
			a = HH(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
			d = HH(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
			c = HH(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
			b = HH(b, c, d, a, x[k + 6], S34, 0x4881d05);
			a = HH(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
			d = HH(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
			c = HH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
			b = HH(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
			a = II(a, b, c, d, x[k + 0], S41, 0xf4292244);
			d = II(d, a, b, c, x[k + 7], S42, 0x432aff97);
			c = II(c, d, a, b, x[k + 14], S43, 0xab9423a7);
			b = II(b, c, d, a, x[k + 5], S44, 0xfc93a039);
			a = II(a, b, c, d, x[k + 12], S41, 0x655b59c3);
			d = II(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
			c = II(c, d, a, b, x[k + 10], S43, 0xffeff47d);
			b = II(b, c, d, a, x[k + 1], S44, 0x85845dd1);
			a = II(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
			d = II(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
			c = II(c, d, a, b, x[k + 6], S43, 0xa3014314);
			b = II(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
			a = II(a, b, c, d, x[k + 4], S41, 0xf7537e82);
			d = II(d, a, b, c, x[k + 11], S42, 0xbd3af235);
			c = II(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
			b = II(b, c, d, a, x[k + 9], S44, 0xeb86d391);
			a = addUnsigned(a, AA);
			b = addUnsigned(b, BB);
			c = addUnsigned(c, CC);
			d = addUnsigned(d, DD);
		}
		var tempValue = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);
		return tempValue.toLowerCase();
	}
};

export default utils;
