import { commonUtils } from '@/common/common-utils';
const initProcess = {
	//节点ID，当前流程内唯一，由前端随机生成
	id: 'root',
	parentId: 0, //父级节点ID，用来向上搜索
	//节点类型，ROOT(发起人，根节点)、APPROVAL(审批)、CC(抄送)、CONDITION(条件)、CONCURRENT（并行节点）、DELAY(延时节点)、EMPTY(空节点，占位)
	type: 'ROOT',
	name: '拟稿人申请',
	props: {
		depIds: [],
		roleIds: [],
		staffIds: [],
		names: '',
		formItems: [],
		storeIds: []
	}, //节点属性
	children: {} //子节点项，内部字段与当前结构相同，为条件/并行 节点的组合末端节点
};
export default {
	state: {
		nodeMap: new Map(),
		isEdit: null,
		selectedNode: {},
		design: {
			formItems: [],
			process: initProcess
		}
	},
	mutations: {
		selectedNode(state, val) {
			state.selectedNode = val;
		},
		loadForm(state, val) {
			state.design = val;
		},
		loadFormItem(state, val) {
			state.design.formItems = val;
		},
		loadProcess(state, val) {
			if (commonUtils.isEmpty(val)) {
				state.design.process = initProcess;
			} else {
				state.design.process = val;
			}
		},
		setIsEdit(state, val) {
			state.isEdit = val;
		}
	},
	getters: {},
	actions: {},
	modules: {}
};
