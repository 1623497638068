<template>
	<div class="containerq">
		<div class="content">
			<div class="title">
				<div @click="clicktitle">{{ data.title }}</div>
			</div>
			<div class="text" v-html="data.content.substr(0, 130)"></div>
			<div class="con">
				<div v-if="isnotice">发布人：{{ data.staffName }}</div>
				<div v-else>作者：{{ data.author }}</div>
				<div v-if="isnotice">{{ data.startDate }}——{{ data.overDate }}</div>
				<div v-else>{{ data.createTime }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		isnotice: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
		success: {
			type: Function
		}
	},
	mounted() {
		// console.log(this.data);

		if (document.documentMode) {
			this.wordlimit('text', 140);
		}
	},
	methods: {
		clicktitle() {
			this.$emit('success');
		},
		wordlimit(cname, wordlength) {
			var cname = document.getElementsByClassName(cname);
			// console.log(cname[0].innerHTML);
			for (var i = 0; i < cname.length; i++) {
				var nowhtml = cname[i].innerHTML;
				var nowlength = cname[i].innerHTML.length;
				if (nowlength > wordlength) {
					cname[i].innerHTML = nowhtml.substr(0, wordlength) + '…';
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.containerq {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 5px;
	border-bottom: 2px solid #f7f7f7;
}

.content {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	width: 100%;
	height: 100%;
	.title {
		cursor: pointer;
		display: flex;
		color: #e96432;
		margin-bottom: 5px;
	}
}

.con {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.text {
	box-sizing: border-box;
	text-indent: 2em;
	font-size: 12px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	/* 显示2行 */
	line-clamp: 2;
	/* 显示2行 */
	white-space: pre-wrap;
}

.img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
</style>
